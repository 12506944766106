import React from "react";
import kit from "../../assets/images/halugen-box-small.jpg";
import {Link} from "react-router-dom";
import {isNullOrUndefinedOrEmpty} from "../../lib/helper";
import ScrollAnimation from "react-animate-on-scroll";

class ExploreKitCTA extends React.PureComponent {
  constructor(props) {
    super(props);

  }

  render() {
    if(isNullOrUndefinedOrEmpty(this.props.product)){
      return null;
    }

    const productTitle = this.props.product.title;
    const price = this.props.product.priceRange.maxVariantPrice.amount;
    const currency = this.props.product.priceRange.maxVariantPrice.currencyCode;

    let bg = 'bg-light'
    if(this.props.bg){
      bg = 'bg-white'
    }
    return (
      <div>
        <section id="pricing" className={bg}>
          <div className="container">

            <div className="row">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="col-md-12 text-center wow">
                <div className="title d-inline-block">
                  <h2 className="gradient-text1 mb-md-3">Start exploring your psychedelics profile today</h2>
                </div>
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn="fadeInUpCard" animateOnce={true}  className="row justify-content-center wow ">
                <div className="price-item item text-center">
                  <h5 className="alt-font gradient-text1 d-inline-block font-weight-500 mb-4">{productTitle}
                  </h5>
                  <div className="price-tag d-flex justify-content-center">

                    <div className="price-image">
                      <img src={kit} alt="image"/>
                    </div>

                    <div className="price mr-0 alt-font text-dark-gray">
                      <h3 className="currency"><span className="sign">
                        {currency === 'CAD'? '' : ''}$</span>{Number.parseFloat(price).toFixed(0)}</h3>
                      <span className="month">FREE SHIPPING</span>
                    </div>

                  </div>
                  <ul className="packages">
                    <li><i className="fa fa-check" aria-hidden="true"></i>Personalized Profile</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i>7 Genetic Markers</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i>Surveys and Questionnaires</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i>Educational Resources</li>
                  </ul>
                  <Link to={'/test'} className="btn btn-large btn-gradient w-100">Buy Now</Link>
                </div>


              </ScrollAnimation>
          </div>
        </section>
      </div>
    )
  }
}

export default ExploreKitCTA
