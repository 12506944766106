import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../actions/index'
import ContactUs from "./ContactUs";

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const ContactUsContainer = connect(mapStateToProps, mapDispatchToProps)(ContactUs);

export default ContactUsContainer
