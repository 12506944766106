import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../../actions/index'
import SurveySummary from "./SurveySummary";

const mapStateToProps = state => ({
  surveys: state.Surveys
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const SurveySummaryContainer = connect(mapStateToProps, mapDispatchToProps)(SurveySummary);

export default SurveySummaryContainer
