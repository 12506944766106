import React from "react";
import {isNullOrUndefinedOrEmpty} from "../../../lib/helper";
import $ from 'jquery'

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      fname: undefined,
      lname: undefined,
      email: undefined,
      month: undefined,
      day: undefined,
      year: undefined,
      gender: undefined,
      country: undefined,
      region: undefined,
      promotionalEmail: false
    }
    const user = this.props.user.userProfile;
    if (!isNullOrUndefinedOrEmpty(user)) {
      this.state.fname = user.fname;
      this.state.lname = user.lname;
      this.state.email = user.email;
      this.state.month = Number.parseInt(user.dob.split('-')[0]);
      this.state.day = Number.parseInt(user.dob.split('-')[1]);
      this.state.year = Number.parseInt(user.dob.split('-')[2]);
      this.state.gender = user.gender;
      this.state.country = user.country;
      this.state.region = user.region;
      this.state.promotionalEmail = user.promotionalEmail;
    }

    this.submitCustomerDetails = this.submitCustomerDetails.bind(this);

  }

  submitCustomerDetails(event) {
    event.preventDefault();
    this.setState({submitted: true})
    const dob = this.state.month + '-' + this.state.day + '-' + this.state.year;
    const payload = {
      id: this.props.user.userProfile.id,
      fname: this.state.fname,
      lname: this.state.lname,
      dob: dob,
      country: this.state.country,
      region: this.state.region,
      gender: this.state.gender,
      promotionalEmail: this.state.promotionalEmail,
      user: {id: this.props.user.userProfile.id}
    }

    this.props.submitCustomerDetails(payload).then(res => {
      this.setState({submitted: false})
      alert('Your profile has been saved.')
      $('#profile-settings-modal').modal('hide')
    }).catch(err => {
      this.setState({submitted: false})
      alert('Something went wrong, please try again!')
    })
  }

  render() {
    return (
      <div>
        <div className="modal fade " role="dialog" tabIndex={-1} id="profile-settings-modal">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title text-primary" style={{color: 'var(--survey-primary)'}}>Profile Settings</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <form id="profile-settings-modal-form" onSubmit={this.submitCustomerDetails}>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group"><label>First Name</label>
                        <input className="form-control" type="text" required
                               pattern="[A-Za-z '-]{2,}" defaultValue={this.state.fname}
                               onChange={(e) => this.setState({fname: e.target.value})}
                               name="firstNameForm"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input className="form-control" type="text" required
                               pattern="[A-Za-z '-]{2,}" defaultValue={this.state.lname}
                               onChange={(e) => this.setState({lname: e.target.value})}
                               name="lastNameForm"/>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <div className="d-flex justify-content-between"><label className="mr-2">Email</label>
                          <a href="#" style={{fontSize: '12px', textDecoration: 'underline'}}
                             data-toggle="modal" data-target="#change-password-modal" data-dismiss="modal">
                            change password
                          </a>
                        </div>
                        <input className="form-control" type="email" required defaultValue={this.state.email}
                               name="emailForm"
                               placeholder="email address" disabled/>
                      </div>
                    </div>
                  </div>
                  <label className="mt-2">Age</label>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group"><select value={this.state.month} className="form-control" required
                                                          name="monthForm"
                                                          onChange={(e) => this.setState({month: e.target.value})}>
                        <option value>Month</option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                      </select></div>
                    </div>
                    <div className="col">
                      <div className="form-group"><select value={this.state.day} className="form-control" required
                                                          name="dayForm"
                                                          onChange={(e) => this.setState({day: e.target.value})}>
                        <option value>Day</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                        <option value={16}>16</option>
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20</option>
                        <option value={21}>21</option>
                        <option value={22}>22</option>
                        <option value={23}>23</option>
                        <option value={24}>24</option>
                        <option value={25}>25</option>
                        <option value={26}>26</option>
                        <option value={27}>27</option>
                        <option value={28}>28</option>
                        <option value={29}>29</option>
                        <option value={30}>30</option>
                        <option value={31}>31</option>
                      </select></div>
                    </div>
                    <div className="col">
                      <div className="form-group"><select value={this.state.year} className="form-control" required
                                                          name="yearForm"
                                                          onChange={(e) => this.setState({year: e.target.value})}>
                        <option value>Year</option>
                        <option value={2003}>2003</option>
                        <option value={2002}>2002</option>
                        <option value={2001}>2001</option>
                        <option value={2000}>2000</option>
                        <option value={1999}>1999</option>
                        <option value={1998}>1998</option>
                        <option value={1997}>1997</option>
                        <option value={1996}>1996</option>
                        <option value={1995}>1995</option>
                        <option value={1994}>1994</option>
                        <option value={1993}>1993</option>
                        <option value={1992}>1992</option>
                        <option value={1991}>1991</option>
                        <option value={1990}>1990</option>
                        <option value={1989}>1989</option>
                        <option value={1988}>1988</option>
                        <option value={1987}>1987</option>
                        <option value={1986}>1986</option>
                        <option value={1985}>1985</option>
                        <option value={1984}>1984</option>
                        <option value={1983}>1983</option>
                        <option value={1982}>1982</option>
                        <option value={1981}>1981</option>
                        <option value={1980}>1980</option>
                        <option value={1979}>1979</option>
                        <option value={1978}>1978</option>
                        <option value={1976}>1976</option>
                        <option value={1975}>1975</option>
                        <option value={1974}>1974</option>
                        <option value={1973}>1973</option>
                        <option value={1972}>1972</option>
                        <option value={1971}>1971</option>
                        <option value={1970}>1970</option>
                        <option value={1969}>1969</option>
                        <option value={1968}>1968</option>
                        <option value={1967}>1967</option>
                        <option value={1966}>1966</option>
                        <option value={1965}>1965</option>
                        <option value={1964}>1964</option>
                        <option value={1963}>1963</option>
                        <option value={1962}>1962</option>
                        <option value={1961}>1961</option>
                        <option value={1960}>1960</option>
                        <option value={1959}>1959</option>
                        <option value={1958}>1958</option>
                        <option value={1957}>1957</option>
                        <option value={1956}>1956</option>
                        <option value={1955}>1955</option>
                        <option value={1954}>1954</option>
                        <option value={1953}>1953</option>
                        <option value={1952}>1952</option>
                        <option value={1951}>1951</option>
                        <option value={1950}>1950</option>
                        <option value={1949}>1949</option>
                        <option value={1948}>1948</option>
                        <option value={1947}>1947</option>
                        <option value={1946}>1946</option>
                        <option value={1945}>1945</option>
                        <option value={1944}>1944</option>
                        <option value={1943}>1943</option>
                        <option value={1942}>1942</option>
                        <option value={1941}>1941</option>
                        <option value={1940}>1940</option>
                        <option value={1939}>1939</option>
                        <option value={1938}>1938</option>
                        <option value={1937}>1937</option>
                        <option value={1936}>1936</option>
                        <option value={1935}>1935</option>
                        <option value={1934}>1934</option>
                        <option value/>
                      </select></div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-4">
                      <div className="form-group"><label>Gender</label>
                        <select value={this.state.gender} className="form-control" name="genderForm"
                                onChange={(e) => this.setState({gender: e.target.value})}>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="OTHER">Other</option>
                        </select></div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group"><label>Country</label>
                        <select value={this.state.country} className="form-control" name="countryForm"
                                onChange={(e) => this.setState({country: e.target.value})}>
                          <option value="ca">Canada</option>
                          <option value="us">United States</option>
                        </select></div>
                    </div>
                    {this.showRegionDropDown('ca') ?
                      <div className="col">
                        <div className="form-group">
                          <label>Province</label>
                          <select value={this.state.region} onChange={(e) => this.setState({region: e.target.value})}
                                  data-blank="No regions" data-ca="Province" data-us="State"
                                  className="form-control" required name="provinceForm">
                            <option value=''>Province</option>
                            <option value="ontario" data-country="ca">Ontario</option>
                            <option value="alberta" data-country="ca">Alberta</option>
                            <option value="british_columbia" data-country="ca">British Columbia</option>
                            <option value="saskatchewan" data-country="ca">Saskatchewan</option>
                            <option value="manitoba" data-country="ca">Manitoba</option>
                            <option value="quebec" data-country="ca">Quebec</option>
                            <option value="newfoundland_and_labrador" data-country="ca">Newfoundland and Labrador
                            </option>
                            <option value="new_brunswick" data-country="ca">New Brunswick</option>
                            <option value="nova_scotia" data-country="ca">Nova Scotia</option>
                            <option value="price_edward_island" data-country="ca">Prince Edward Island</option>
                          </select>
                        </div>
                      </div> : null}
                    {this.showRegionDropDown('us') ?
                      <div className="col">
                        <div className="form-group">
                          <label>State</label>
                          <select value={this.state.region} onChange={(e) => this.setState({region: e.target.value})}
                                  data-blank="No regions" data-ca="Province" data-us="State"
                                  className="form-control" required name="stateForm">
                            <option value="">State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                      </div> : null}
                  </div>
                  <label className="mt-2">Marketing</label>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input className="custom-control-input" type="checkbox"
                             id="formCheck-marketing-opt-in" checked={this.state.promotionalEmail}
                             onChange={(e) => this.setState({promotionalEmail: !this.state.promotionalEmail})}
                             name="marketing-opt-in"/><label className="custom-control-label"
                                                             htmlFor="formCheck-marketing-opt-in">Exclusive
                      promotions and updates</label></div>
                  </div>
                  <div className="form-group text-center mt-4">
                    {!this.state.submitted ?
                      <button className="btn btn-primary btn-lg border rounded" type='submit'>Update Settings
                      </button> :
                      <button className="btn btn-primary btn-lg border rounded" type='button'>Saving...
                      </button>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  showRegionDropDown(type) {
    if (this.state.country === 'ca') {
      if (type !== 'ca') {
        return false
      }
    } else if (this.state.country === 'us') {
      if (type !== 'us') {
        return false
      }
    }

    return true;
  }

}

export default Profile
