import React from "react";
import shrooms from '../../assets/images/magic-mushrooms.jpg'
import {Link} from "react-router-dom";
import {isNullOrUndefinedOrEmpty} from "../../lib/helper";
import Helmet from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";

class OurTests extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Psychedelics Genetic Test | HaluGen Life Sciences</title>

          <meta itemProp="name" content="Psychedelics Genetic Test | HaluGen Life Sciences"/>
          <meta itemProp="description"
                content="Order your Psychedelics Genetic Test online and learn how you react to psychedelics with a personalized profile."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description"
                content="Order your Psychedelics Genetic Test online and learn how you react to psychedelics with a personalized profile."/>
          <meta property="og:title" content="Psychedelics Genetic Test | HaluGen Life Sciences"/>
          <meta property="og:description"
                content="Order your Psychedelics Genetic Test online and learn how you react to psychedelics with a personalized profile."/>
          <meta property="og:url" content="https://www.halugen.com/test.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="Psychedelics Genetic Test | HaluGen Life Sciences"/>
          <meta name="twitter:description"
                content="Order your Psychedelics Genetic Test online and learn how you react to psychedelics with a personalized profile."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
        </Helmet>
      </>
    )
  }

  render() {
    if (isNullOrUndefinedOrEmpty(this.props.product)) {
      return (
        <div className='min-vh-100 d-flex justify-content-center align-items-center align-content-center'>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
    const productTitle = this.props.product.title;
    const descriptionHtml = this.props.product.descriptionHtml;
    const isAvailableForSale = this.props.product.availableForSale;
    const price = this.props.product.priceRange.maxVariantPrice.amount;
    const currency = this.props.product.priceRange.maxVariantPrice.currencyCode;
    const images = this.props.product.images.edges;

    return (
      <>
        {this.renderMetaTags()}
        <section id="test" className='mt-0 pb-0'>
          <div className="container">
            <section className="row py-5">
              <div className="col-lg-5 pb-4 pb-lg-0">
                <div id="carousel" className="carousel slide " data-interval="false" data-ride="carousel">
                  <div className="carousel-inner mb-3">
                    <div className="carousel-item active">
                      <img src={images[0].node.src}/>
                    </div>
                    <div className="carousel-item">
                      <img src={images[1].node.src}/>
                    </div>
                    <div className="carousel-item">
                      <img src={images[2].node.src}/>
                    </div>
                    <div className="carousel-item">
                      <img src={images[3].node.src}/>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="carousel-link">
                      <div data-target="#carousel" data-slide-to={0} className="thumb"><img src={images[0].node.src}/></div>
                      <div data-target="#carousel" data-slide-to={1} className="thumb"><img src={images[1].node.src}/></div>
                      <div data-target="#carousel" data-slide-to={2} className="thumb"><img src={images[2].node.src}/></div>
                      <div data-target="#carousel" data-slide-to={3} className="thumb"><img src={images[3].node.src}/></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1">
                <div className="card-content-text">
                  <h2 className="gradient-text1">{productTitle}</h2>
                  <div dangerouslySetInnerHTML={{__html: descriptionHtml}}></div>
                </div>
                <div className="price-tag d-flex">
                  <ul className="packages">
                    <li>
                      {!isAvailableForSale ?
                        <h6><i className="fa fa-times" aria-hidden="true"/>Out of stock</h6> :
                        <h6><i className="fa fa-check" aria-hidden="true"/>In stock</h6>}
                    </li>
                  </ul>
                </div>
                <ul className="d-flex justify-content-between align-items-center list-unstyled text-left my-4">
                  <li>
                    <h3 className="d-inline-block gradient-text1">{currency === 'CAD'? '' : ''}${Number.parseFloat(price).toFixed(0)}</h3>
                  </li>
                  <li>
                    <h5>Free Shipping</h5>
                  </li>
                </ul>
                {isAvailableForSale ?
                  <Link to='/cart' className={"btn btn-large btn-gradient mt-3"}
                        onClick={() => this.props.addProductToCart(1)}>Add to cart</Link>
                  : <div className={"btn btn-large btn-gradient mt-3 disabled"}>Add to cart</div>
                }
              </div>
            </section>

            <section className="accordion pt-2 pb-1" id="accordionReportPages">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse0"
                            aria-expanded="true" aria-controls="collapse0">
                      Classical Psychedelics Report
                    </button>
                  </h5>
                </div>
                <div id="collapse0" className="collapse show" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <div className="row no-gutters">
                      <div className="col-lg-5">
                        <h5 className="card-title gradient-text1">Your serotonin sensitivity</h5>
                        <p className="card-content-text pt-3">The way your body responds to classical
                          psychedelics such as
                          psilocybin, LSD and DMT, is influenced by the HTR2A gene. This gene influences
                          the baseline expression of the 5-HTR2A receptor, which binds to serotonin
                          and releases neurotransmitters in the brain. These neurotransmitters can cause
                          potential
                          hallucinations and other mental effects of the classical psychedelic "trip".
                        </p>
                        <p className="card-content-text">20% of people carry an HTR2A genetic variant which can
                          increase receptor
                          density, potentially making them more susceptible to stronger hallucinogenic
                          effects. These
                          individuals should be more cautious when considering classical psychedelic
                          therapies.</p>
                      </div>
                      <div className="col-lg-5 offset-lg-1 pt-4 pt-lg-0">
                        <h5 className="card-title gradient-text1">Classical psychedelics report includes</h5>
                        <ul className="card-content-text">
                          <li className="">Insight into serotonin response and how it can affect a "trip"</li>
                          <li className="">Genetics by population and ethnicity</li>
                          <li className="">How serotonin receptor density can also influence SSRI drug
                            response and much more
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                      Ketamine Response Report
                    </button>
                  </h5>
                </div>
                <div id="collapse4" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">
                  <div className="card-body">
                    <div className="row no-gutters">
                      <div className="col-lg-5">
                        <h5 className="card-title gradient-text1">Your response to ketamine</h5>
                        <p className="card-content-text pt-3">The way your body responds to ketamine is influenced by the BDNF gene. This gene influences the secretion of brain-derived neurotrophic factor (BDNF) protein, which is released when being dosed with ketamine. BDNF helps with the growth and maintenance of new neurons and synapses that are necessary for normal mood and brain connectivity. Stress and anxiety inhibit the production of BDNF which can lead to mood disorders such as PTSD and depression.</p>
                        <p className="card-content-text">30% of people carry the BDNF 'Met' genetic variant that can cause impaired secretion of BDNF. These individuals are more likely to have a decreased response to the antidepressant effects of ketamine therapy.</p>
                      </div>
                      <div className="col-lg-5 offset-lg-1 pt-4 pt-lg-0">
                        <h5 className="card-title gradient-text1">Ketamine response report includes</h5>
                        <ul className="card-content-text">
                          <li className="">Insight into how the BDNF gene can affect ketamine response</li>
                          <li className="">Genetics by population and ethnicity</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                      Ketamine Metabolism Report
                    </button>
                  </h5>
                </div>
                <div id="collapse1" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">
                  <div className="card-body">
                    <div className="row no-gutters">
                      <div className="col-lg-5">
                        <h5 className="card-title gradient-text1">Your metabolism of ketamine</h5>
                        <p className="card-content-text pt-3">The way your body metabolizes ketamine, a
                          fast-acting anesthetic with hallucinogenic properties, is influenced by the CYP2B6 gene. This gene
                          encodes a liver enzyme that helps metabolize ketamine in your bloodstream. 10–20% of
                          people carry the CYP2B6*6 genetic variant which causes them to metabolize ketamine up to 2x to 3x
                          slower than normal.</p>
                        <p className="card-content-text">These ‘slow metabolizers’ should be more cautious when
                          being dosed with ketamine,
                          as they will likely experience an increased duration and intensity of effect,
                          especially when taking ketamine in intravenous or subcutaneous form.
                        </p>
                      </div>
                      <div className="col-lg-5 offset-lg-1 pt-4 pt-lg-0">
                        <h5 className="card-title gradient-text1">Ketamine metabolism report includes</h5>
                        <ul className="card-content-text">
                          <li className="">Insight into intensity and duration of effects</li>
                          <li className="">Genetics by population and ethnicity</li>
                          <li className="">How metabolism can vary by ketamine format and much more</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                      LSD, MDMA, Ayahuasca Metabolism Report
                    </button>
                  </h5>
                </div>
                <div id="collapse2" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">
                  <div className="card-body">
                    <div className="row no-gutters">
                      <div className="col-lg-5">
                        <h5 className="card-title gradient-text1">Your metabolism of LSD, MDMA, ayahuasca</h5>
                        <p className="card-content-text pt-3">The way your body metabolizes LSD, MDMA and ayahuasca, is influenced by the CYP2D6 gene. This gene encodes a liver enzyme that helps metabolize these drugs in your bloodstream. 5–10% of people carry the CYP2D6 genetic variant which causes them to metabolize LSD, MDMA and ayahuasca up to 2x slower than normal.</p>
                        <p className="card-content-text">Individuals with the ‘poor metabolizer’ phenotype should be more cautious when being dosed with LSD, MDMA or ayahuasca, as they will likely experience an increased duration and intensity of effect when taken in oral form.</p>
                      </div>
                      <div className="col-lg-5 offset-lg-1 pt-4 pt-lg-0">
                        <h5 className="card-title gradient-text1">LSD, MDMA, ayahuasca metabolism report includes</h5>
                        <ul className="card-content-text">
                          <li className="">Insight into intensity and duration of effects</li>
                          <li className="">Genetics by population and ethnicity</li>
                          <li className="">Metabolizer phenotype and much more</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                      Mental Health Risk Report
                    </button>
                  </h5>
                </div>
                <div id="collapse3" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">
                  <div className="card-body">
                    <div className="row no-gutters">
                      <div className="col-lg-5">
                        <h5 className="card-title gradient-text1">Your risk for psychedelics-related mental
                          health effects</h5>
                        <p className="card-content-text pt-3">Genetics play an important role in your short- and
                          long-term risk for
                          mental health effects such as schizophrenia and psychosis. The NRG1, DISC1 and
                          C4A genes have
                          all shown to influence your genetic risk for these effects.</p>
                        <p className="card-content-text">Studies have shown that misuse of psychedelics combined
                          with genetic risk factors
                          can increase the risk of negative mental health effects.</p>
                      </div>
                      <div className="col-lg-5 offset-lg-1 pt-4 pt-lg-0">
                        <h5 className="card-title gradient-text1">Mental health risk report includes</h5>
                        <ul className="card-content-text">
                          <li className="">Short-term risk of adverse psychedelic-induced effects</li>
                          <li className="">Long-term risk of psychosis and schizophrenia</li>
                          <li className="">Genetics by population and ethnicity</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>

          </div>

        </section>


        <section id="benefits" className="bg-light">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="col-md-12 text-center wow">
              <div className="title d-inline-block">
                <h2 className="gradient-text1 mb-md-3">Future updates and exclusive benefits</h2>
              </div>
            </ScrollAnimation>

            <div className="row align-items-center">
              <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="col-md-6 order-md-2 wow">

                <div className="card-content-text">
                  <h2>Explore your psychedelics genetic profile and more.</h2>
                  <ul className="card-content-text">
                    <li>In addition to your reports, we're constantly updating our
                      platform to bring you future insights and benefits including:
                    </li>
                    <li>Tools and recommendations to better understand your psychedelics profile</li>
                    <li>The latest science and research news</li>
                    <li>Exclusive offers from our partners</li>
                  </ul>
                </div>
              </ScrollAnimation>
              <div className="col-md-6">

                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="card-content-image wow hover-effect ">
                  <img src={shrooms} srcSet={shrooms}
                       focusable="false" role="img" alt=""/>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>


        <section id="learnmore-hiw">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" className="col-md-12 text-center wow">
              <div className="title d-inline-block">
                <h2 className="gradient-text1">Want to know more?</h2>
              </div>
            </ScrollAnimation>

            <div className="text-center">
              <Link to="/how-it-works" className="btn btn-gradient btn-rounded btn-large">Learn How It Works</Link>
            </div>
          </div>
        </section>
      </>
    )
  }

}

export default OurTests
