import React from "react";

class Startup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.statupAcknowledge()
  }

  render() {
    return null;
  }

}
export default Startup
