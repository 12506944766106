/* eslint-disable */
import React from 'react'
import logowhite from "../../assets/images/halugen-logo-white.png";
import {Link} from "react-router-dom";

class WholeSalerHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 220) { // Set position from top to add class
        $('header').addClass('header-appear');
      } else {
        $('header').removeClass('header-appear');
      }
    });

    if ($("#sidemenu_toggle").length) {
      $("#sidemenu_toggle").on("click", function () {
        $(".pushwrap").toggleClass("active");
        $(".side-menu").addClass("side-menu-active"), $("#close_side_menu").fadeIn(700)
      }), $("#close_side_menu").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $(this).fadeOut(200), $(".pushwrap").removeClass("active")
      }), $(".side-nav .navbar-nav .nav-link").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      }), $("#btn_sideNavClose").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      }), $("#btn-signout").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      }), $("#btn-signup").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      }), $("#btn-register").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      });

      $('.navbar .dropdown').hover(function () {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();
      }, function () {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp()
      });
    }

    if ($(".side-right-btn").length) {

      $(".side-right-btn").click(function () {
        $(".navbar.navbar-right").toggleClass('show');
      }),
        $(".navbar.navbar-right .navbar-nav .nav-link").click(function () {
          $(".navbar.navbar-right").toggleClass('show');
        });

    }
  }

  render() {
    return (
      <header>
        {this.renderNav()}
      </header>
    )
  }

  renderNav() {
    return (
      <header className='main-website'>
        <nav className='navbar navbar-top-default navbar-expand-lg gradient-bg1 navbar-gradient nav-box-round'>
          <div className="container px-1 px-md-3">
            <Link to="/" title="Logo" className="logo scroll">
              <img src={this.getLogo()} alt="logo" className="logo-dark default mr-5" style={{minWidth: '135px'}}/>
            </Link>
          </div>
        </nav>
      </header>
    )
  }

  getLogo() {
    return logowhite
  }
}

export default WholeSalerHeader;
