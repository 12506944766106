import React from 'react'
import './Footer.scss'
import {Link, withRouter} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ActionCreators} from "../../actions";
import UserCountry from "../UserCountry/UserCountry";

class Footer extends React.Component {
  constructor(props) {
    super(props);

  }


  render() {
    return (<>

      { window.location.pathname === '/cart' && <UserCountry align="center" /> }
      <section className="bg-light text-center mb-0">
        <div className="container">

          <div className="container text-center text-md-left">
            <div className="row">

              <div className="col-md-3 col-lg-3 col-xl-3 mb-4">
                <h6 className="gradient-text1 text-uppercase font-weight-bold">
                  <strong>Information</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px'}}/>
                <p className="mb-1">
                  <Link id="footer-link-test" to="/test">Psychedelics Genetic Test</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-how-it-works" to="how-it-works">How it Works</Link>
                </p>
              </div>


              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="gradient-text1 text-uppercase font-weight-bold">
                  <strong>Customers</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px'}}/>
                <p className="mb-1">
                  <Link id="footer-link-register" to="/register">Register Kit</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-cart" to="/cart">Shopping Cart</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-kit-instructions" to="/swab-kit-instructions">Swab Kit Instructions</Link>
                </p>
              </div>


              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="gradient-text1 text-uppercase font-weight-bold">
                  <strong>Terms</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px'}}/>

                <p className="mb-1">
                  <Link id="footer-link-privacy" to="/privacy">Privacy Statement</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-terms-of-service" to="/terms-of-service">Terms of Service</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-terms-of-sale" to="/term-of-sale">Terms of Sale</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-refund-policy" to="/refund-policy">Refund Policy</Link>
                </p>
              </div>


              <div className="col-md-3 col-lg-3 col-xl-3">
                <h6 className="gradient-text1 text-uppercase font-weight-bold">
                  <strong>About Us</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px'}}/>
                <p className="mb-1">
                  <Link id="footer-link-company" to="/about">
                    <i className="fa fa-users mr-3"></i> Company</Link>
                </p>
              </div>

            </div>
          </div>

          { window.location.pathname != '/cart' && <UserCountry/> }

          <div className="row">
            <div className="col-md-12">
              <div className="footer-social">
                <ul className="list-unstyled">
                  <li>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                      <a className="wow" href="https://www.facebook.com/halugensci" target="_blank">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                  <li>
                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                      <a className="wow" href="https://twitter.com/halugensci" target="_blank">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                  <li>
                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                      <a className="wow" href="https://www.linkedin.com/company/halugensci" target="_blank">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                  <li>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                      <a className="wow" href="https://www.instagram.com/halugensci" target="_blank">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                  <li>
                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                      <a className="wow" href="mailto:info@halugen.com" target="_blank">
                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                </ul>
              </div>
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <p className="company-about">Your use of our services and the information provided through this
                  website, including test results and reports, is for informational purposes only, and is not
                  intended for medical advice or treatment, or for other therapeutic or diagnostic use. Before
                  taking any action based on this information, you must consult an experienced healthcare
                  professional. Your use of our services and this website is governed by the laws of the Province
                  of Ontario and the federal laws of Canada. &copy; 2022 HaluGen Life Sciences. All rights
                  reserved.</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      </>
    )
  }
}

const mapStatetoProps = state => (
  {}
)

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Footer));

