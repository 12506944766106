import React from 'react';
import {withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ActionCreators} from "../../actions";

class UserCountry extends React.Component {
  constructor(props) {
    super(props);

  }


  render() {
    const align = this.props.align ? this.props.align : "center";
    const justify = this.props.align && this.props.align != 'center' ? this.props.align === 'right' ? "end" : "start" : "center";
    return (
      <div className={`d-flex align-items-${align} justify-content-${justify} ${this.props.align ? 'mb-3' : 'mt-5 mt-sm-1 mt-md-0 mr-5 ml-5'}`}>
        <div className=" dropdown d-flex">
          <a className="nav-link " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
            <div className='pointer nav-item dropdown d-flex align-items-center'>
              {this.renderUserCountry()}
              {this.renderCountryText()}
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-right dropdown-menu-xl-left" style={{width: "max-content"}}
               aria-labelledby="navbarDropdown">
            <div className="dropdown-item py-3 pointer" onClick={() => this.modifyUserCountry('Canada')}>
              <img className='pr-2' src={require('../../assets/images/ca.png')} style={{height: 18}}/> Canada
            </div>
            <div className="dropdown-item py-3 pointer" onClick={() => this.modifyUserCountry('United States')}>
              <img className='pr-2' src={require('../../assets/images/us.png')} style={{height: 18}}/>United
              States
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderUserCountry() {
    if (this.props.userCountry === 'Canada')
      return (
        <img src={require('../../assets/images/ca.png')} style={{height: 18}}/>
      )

    if (this.props.userCountry === 'United States')
      return (
        <img src={require('../../assets/images/us.png')} style={{height: 18}}/>
      )

    return null;
  }

  modifyUserCountry(country) {
    if (country === this.props.userCountry)
      return;

    this.props.modifyUserCountry(country);
    toast.info('Your country has been updated', {position: 'top-center', autoClose: 2500});
  }

  renderCountryText() {
    if (this.props.partnerOrdering) {
      return (
        <p className='pl-2 text-black'>{this.props.userCountry} Shipping | <span
          className='change-country'>Change</span></p>
      )
    }

    return (
      <p className='pl-2 text-black'>{this.props.userCountry} | <span
        className='change-country'>Change</span></p>
    )
  }
}

const mapStatetoProps = state => ({
  userCountry: state.UserCountry.country
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(UserCountry));

