import * as types from './types'

export const saveAffiliateRef = response => {
  return {
    type: types.SAVE_AFFILIATE_REF,
    payload: {response},
  }
}
export const saveDiscount = response => {
  return {
    type: types.SAVE_DISCOUNT_CODE,
    payload: {response},
  }
}
