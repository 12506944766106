import React from "react";
import {isNullOrUndefinedOrEmpty} from "../../lib/helper";
import Helmet from "react-helmet";
import {toast} from "react-toastify";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
    this.handleCheckout = this.handleCheckout.bind(this);
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Shopping Cart | HaluGen Life Sciences</title>


          <meta itemProp="name" content="Shopping Cart | HaluGen Life Sciences"/>
          <meta itemProp="description" content="Shopping Cart for HaluGen Life Sciences."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description" content="Shopping Cart for HaluGen Life Sciences."/>
          <meta property="og:title" content="Shopping Cart | HaluGen Life Sciences"/>
          <meta property="og:description" content="Shopping Cart for HaluGen Life Sciences."/>
          <meta property="og:url" content="https://www.halugen.com/cart-empty.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="Shopping Cart | HaluGen Life Sciences"/>
          <meta name="twitter:description" content="Shopping Cart for HaluGen Life Sciences."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    if (isNullOrUndefinedOrEmpty(this.props.product)) {
      return null;
    }
    const productTitle = this.props.product.title;
    const productType = 'CONSUMER';
    const price = this.props.product.priceRange.maxVariantPrice.amount;
    return (
      <>
        {this.renderMetaTags()}
        <section id="cart mt-0">
          <div className="container">

            <div className="row pt-5 pt-md-3">
              <div className="col-md-12 text-center">
                <div className="title d-inline-block">
                  <h2 class="gradient-text1 mt-3 mt-md-0 mb-md-3">Your cart</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8">
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="mb-4">Cart </h5>
                    <div className="row">
                      <div className="col-md-4 col-lg-3 col-xl-3">
                        <div className="rounded mb-3 mb-md-0">
                          <img className="img-fluid w-100"
                               src={require('../../assets/images/halugen-box-half-block2.png')} alt="Line item image"/>
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-9 col-xl-9 text-left">
                        <div className="row justify-content-between">
                          <div className="col-12 col-sm-6">
                            <h6>{productTitle}</h6>
                            <p className="my-2 text-muted text-uppercase small">Your psychedelics geneti...</p>
                          </div>
                          <div className="col-12 col-sm-6 pt-3 pt-sm-0">
                            <div className="d-flex justify-content-center justify-content-sm-end">
                              <div className="def-number-input number-input safari_only mb-0"
                                   data-controller="number-counter">
                                <button className="minus-cart h-100" onClick={() => this.props.addProductToCart(-1, productType)}
                                        data-action="number-counter#decrement">
                                  <i className="fa fa-minus"/>
                                </button>
                                <input data-number-counter-target="number" type="number"
                                       value={this.props.cart.cart.itemCount}
                                       name="shop_cart[quantity]" id="shop_cart_quantity"/>
                                <button className="plus-cart h-100" onClick={() => this.props.addProductToCart(1, productType)}
                                        data-action="number-counter#increment">
                                  <i className="fa fa-plus"/>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-3">
                          <p className="mb-0"><span><strong>${Number.parseFloat(price).toFixed(2)}</strong></span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="mb-4">We accept</h5>
                    <img className="mr-2" width="45px" src={require('../../assets/images/visa.svg')} alt="Visa"/>
                    <img className="mr-2" width="45px" src={require('../../assets/images/amex.svg')}
                         alt="American Express"/>
                    <img className="mr-2" width="45px" src={require('../../assets/images/mastercard.svg')}
                         alt="Mastercard"/>
                    <img className="mr-2" width="75px" src={require('../../assets/images/PayPal.svg')} alt="PayPal"/>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 h-100">
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="mb-3">Subtotal</h5>
                    <ul className="list-group list-group-flush text-left">
                      <li
                        className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        Amount
                        <span>${(Number.parseFloat(price).toFixed(2) * this.props.cart.cart.itemCount).toFixed(2)}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                        Taxes and discount codes will be applied on checkout
                      </li>
                      {!isNullOrUndefinedOrEmpty(this.props.discounts) ?
                        <li>
                          <div className='btn bg-light text-success' style={{fontSize: '16px', cursor: 'auto'}}>
                            <i className="fa fa-tags pr-2" aria-hidden="true"></i>
                            {this.props.discounts}
                          </div>
                        </li>
                        : null}
                      {!isNullOrUndefinedOrEmpty(this.props.affiliateRef) ?
                        <li>
                          <div className='btn bg-light text-success' style={{fontSize: '16px', cursor: 'auto'}}>
                            <i className="fa fa-tags pr-2" aria-hidden="true"></i>
                            {this.props.affiliateRef}
                          </div>
                        </li>
                        : null}
                      <li
                        className="list-group-item d-flex justify-content-between align-items-center mb-0 px-0 mb-3">
                        <div>
                          <strong>Subtotal</strong>
                        </div>
                        <span><strong>${(Number.parseFloat(price).toFixed(2) * this.props.cart.cart.itemCount).toFixed(2)}</strong></span>
                      </li>
                    </ul>

                    <div className="row justify-content-center mt-2">
                      {this.state.loading ?
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        : this.props.cart.cart.itemCount > 0 ?
                          <div className="btn btn-checkout" onClick={() => this.handleCheckout()}>Checkout</div> :
                          <div className="btn btn-checkout disabled">Checkout</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  handleCheckout() {
    this.setState({loading: true})
    this.props.getCheckoutURL(this.props.cart.cart.itemCount).then(res => {
      let url = res.data.checkoutCreate.checkout.webUrl;
      if (!isNullOrUndefinedOrEmpty(this.props.discounts) && !isNullOrUndefinedOrEmpty(this.props.affiliateRef)) {
        url += '&discount=' + this.props.discounts + '&ref=' + this.props.affiliateRef
      } else if (!isNullOrUndefinedOrEmpty(this.props.discounts)) {
        url += '&discount=' + this.props.discounts
      } else if (!isNullOrUndefinedOrEmpty(this.props.affiliateRef)) {
        url += '&discount=' + this.props.affiliateRef
      }

      window.open(url, '_self')
      this.props.emptyCart()
      this.setState({loading: false})
    }).catch(err => {
      toast.error('Something went wrong, please try again. If the problem persist please try again later.')
      this.setState({loading: false})
      console.error(err);
    })
  }
}

export default Cart
