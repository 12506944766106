import {bfetch} from "../lib/http";
import {GET_SHOPIFY_CHECKOUT_URL} from "../lib/constants";

export const getCheckoutURL = (quantity) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const cart = getState().Cart.cart;
      const userCountry = getState().UserCountry.country
      const url = GET_SHOPIFY_CHECKOUT_URL + '?quantity=' + quantity + '&country=' + userCountry +
      '&productType=' + cart.productType;
      bfetch(url).then(res => {
        resolve(res)
      }).catch(err =>{
        reject(err)
      })
    })
  }
}
