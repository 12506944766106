import React from "react";
import {getBipolarResultStr} from "../../../lib/survey-helper";
import {SURVEY_TYPE} from "../../../lib/constants";
import {isNullOrUndefined, isNullOrUndefinedOrEmpty} from "../../../lib/helper";

class SurveySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bipolarResult: undefined,
      psyResult: undefined,
      familyResult: undefined,
      alcoholResult: undefined,
      drugResult: undefined
    }

    const surveys = this.props.surveys;
    surveys.forEach(survey => {
      if (survey.surveryType === SURVEY_TYPE.BIPOLAR) {
        this.state.bipolarResult = survey.result;
      }
      if (survey.surveryType === SURVEY_TYPE.LIFE_STYLE_DRUGS) {
        this.state.drugResult = survey.result;
      }
      if (survey.surveryType === SURVEY_TYPE.LIFE_STYLE_ALCOHOL) {
        this.state.alcoholResult = survey.result;
      }
      if (survey.surveryType === SURVEY_TYPE.PSYCHOSIS) {
        this.state.psyResult = survey.result;
      }
      if (survey.surveryType === SURVEY_TYPE.FAMILY_HISTORY) {
        this.state.familyResult = survey.result;
      }
    })
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const surveys = nextProps.surveys;
    if(isNullOrUndefinedOrEmpty(surveys)){
      this.state.bipolarResult = undefined
      this.state.drugResult = undefined
      this.state.alcoholResult = undefined
      this.state.psyResult = undefined
      this.state.familyResult = undefined
    }
    surveys.forEach(survey => {
      if (survey.surveryType === SURVEY_TYPE.BIPOLAR) {
        this.state.bipolarResult = survey.result;
      }
      if (survey.surveryType === SURVEY_TYPE.LIFE_STYLE_DRUGS) {
        this.state.drugResult = survey.result;
      }
      if (survey.surveryType === SURVEY_TYPE.LIFE_STYLE_ALCOHOL) {
        this.state.alcoholResult = survey.result;
      }
      if (survey.surveryType === SURVEY_TYPE.PSYCHOSIS) {
        this.state.psyResult = survey.result;
      }
      if (survey.surveryType === SURVEY_TYPE.FAMILY_HISTORY) {
        this.state.familyResult = survey.result;
      }
    })

    // what if multiple surveys are filled and one is clear.. apply logic below
    const arr = [SURVEY_TYPE.BIPOLAR, SURVEY_TYPE.LIFE_STYLE_DRUGS, SURVEY_TYPE.LIFE_STYLE_ALCOHOL, SURVEY_TYPE.PSYCHOSIS, SURVEY_TYPE.FAMILY_HISTORY]
    arr.forEach(item => {
      let flag = false;
      surveys.forEach(survey => {
        if(survey.surveryType === item){
          flag = true;
        }
      })
      if(!flag){
        if (item === SURVEY_TYPE.BIPOLAR) {
          this.state.bipolarResult = undefined;
        }
        if (item === SURVEY_TYPE.LIFE_STYLE_DRUGS) {
          this.state.drugResult = undefined;
        }
        if (item === SURVEY_TYPE.LIFE_STYLE_ALCOHOL) {
          this.state.alcoholResult = undefined;
        }
        if (item === SURVEY_TYPE.PSYCHOSIS) {
          this.state.psyResult = undefined;
        }
        if (item === SURVEY_TYPE.FAMILY_HISTORY) {
          this.state.familyResult = undefined;
        }
      }
    })

    return true;
  }

  render() {
    const bipolarResultStr = getBipolarResultStr(this.state.bipolarResult)
    const psyResultStr = this.state.psyResult
    const familyResultStr = !isNullOrUndefined(this.state.familyResult) ? JSON.parse(this.state.familyResult) : undefined;
    const alchResultStr = !isNullOrUndefined(this.state.alcoholResult) ? JSON.parse(this.state.alcoholResult) : undefined;
    const drugResultStr = this.state.drugResult;
    return (
      <div>
        <div className="modal fade" role="dialog" tabIndex={-1} id="summary-surveys-modal1">
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" style={{color: 'var(--survey-primary)'}}>Summary Survey Report</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <div className="row no-gutters" id="bipolar-survey-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3"
                         src={require("../../../assets/images/img-mind-white-transp.png")} width="20%"
                         style={{background: 'var(--survey-primary)', padding: '10px'}}/>
                    <div>
                      <h4>Bipolar Survey</h4>
                      <p>Based on the Goldberg Bipolar Screening Test for possible disorder in the bipolar spectrum in individuals.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div id="bipolar-summary-results">
                      <div className="text-center">
                        <h4 className="mb-2">Your Score</h4>
                        {isNullOrUndefined(bipolarResultStr) ?
                          <h4 className='text-secondary'>SURVEY INCOMPLETE</h4> :
                          <h4 style={{color: 'var(--survey-primary)'}}>{bipolarResultStr}</h4>}
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row no-gutters" id="psychosis-survey-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3"
                         src={require("../../../assets/images/img-mind-white-transp.png")} width="20%"
                         style={{background: 'var(--survey-primary)', padding: '10px'}}/>
                    <div>
                      <h4>Psychosis Survey</h4>
                      <p>Based on the Yale University mPRIME Screening tool to quickly assess risk for developing a psychotic disorder.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div id="psychosis-summary-results">
                      <div className="text-center">
                        <h4 className="mb-2">Your Score</h4>
                        {isNullOrUndefined(psyResultStr) ?
                          <h4 className='text-secondary'>SURVEY INCOMPLETE</h4> : psyResultStr === 'Positive' ?
                            <h4 style={{color: 'var(--survey-primary)'}}>Positive Screen&nbsp;<i className="fa fa-plus-circle"/></h4> :
                            <h4 style={{color: 'var(--survey-primary)'}}>Negative Screen&nbsp;<i className="fa fa-minus-circle"/></h4>}
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row no-gutters" id="family-survey-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2 survey-summary-col-margin">
                    <img className="mb-auto mr-3"
                         src={require("../../../assets/images/img-family-white-transp.png")}
                         width="20%" style={{
                      background: '#b81f84',
                      padding: '10px'
                    }}/>
                    <div>
                      <h4>Family History Survey</h4>
                      <p>Familial risk of a major mental illness like schizophrenia. Survey responses next to blood relative categories.</p>
                    </div>
                  </div>
                  <div className="col" id="family-summary-results">
                    {!isNullOrUndefined(familyResultStr) ?
                      <>
                        <h4 className="mb-2">Your Score</h4>
                        <div className="table-responsive">
                          <table className="table scoring-table">
                            <thead>
                            <tr>
                              <th>Relative</th>
                              <th>Risk</th>
                              <th>Responses</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>Identical Twin</td>
                              <td>48%</td>
                              <td>
                                {this.getChecksForResult(familyResultStr.identicalTwin)}
                              </td>
                            </tr>
                            <tr>
                              <td>Fraternal Twin</td>
                              <td>17%</td>
                              <td>
                                {this.getChecksForResult(familyResultStr.fraternalTwin)}
                              </td>
                            </tr>
                            <tr>
                              <td>2 Parents</td>
                              <td>35%</td>
                              <td>
                                {this.getChecksForResult(familyResultStr.twoParents)}
                              </td>
                            </tr>
                            <tr>
                              <td>1 Parent</td>
                              <td>13%</td>
                              <td>
                                {this.getChecksForResult(familyResultStr.oneParent)}
                              </td>
                            </tr>
                            <tr>
                              <td>Full Sibling</td>
                              <td>9%</td>
                              <td>
                                {this.getChecksForResult(familyResultStr.fullSibling)}
                              </td>
                            </tr>
                            <tr>
                              <td>Half Sibling</td>
                              <td>6%</td>
                              <td>
                                {this.getChecksForResult(familyResultStr.halfSibling)}
                              </td>
                            </tr>
                            <tr>
                              <td>Other Relatives</td>
                              <td>2%</td>
                              <td>
                                {this.getChecksForResult(familyResultStr.otherRelatives)}
                              </td>
                            </tr>
                            <tr>
                              <td>General Population</td>
                              <td>1%</td>
                              <td/>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </> :
                      <div className='text-center'>
                        <h4 className='text-secondary'>SURVEY INCOMPLETE</h4>
                      </div>}
                  </div>
                </div>
                <hr/>
                <div className="row no-gutters" id="lifestyle-alcohol-survey-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3"
                         src={require("../../../assets/images/img-lifestyle-white-transp.png")} width="20%"
                         style={{background: '#f47da7', padding: '10px'}}/>
                    <div>
                      <h4>Lifestyle Alcohol Survey</h4>
                      <p>Based on Alcohol DSM-IV disorder criteria for both alcohol abuse and dependence.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div id="lifestyle-alcohol-summary-results">
                      <div className="text-center">
                        <h4 className="mb-2">Your Score</h4>
                        {!isNullOrUndefined(alchResultStr) ?
                          <>
                            <h4 style={{color: 'var(--survey-primary)'}}>Abuse: {alchResultStr && alchResultStr.abuse >= 1 ? 'Yes' : 'No'}</h4>
                            <h4
                              style={{color: 'var(--survey-primary)'}}>Dependence: {alchResultStr && alchResultStr.dependence >= 3 ? 'Yes' : 'No'}</h4>
                          </>
                          : <h4 className='text-secondary'>SURVEY INCOMPLETE</h4>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row no-gutters" id="lifestyle-drugs-survey-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3"
                         src={require("../../../assets/images/img-lifestyle-white-transp.png")} width="20%"
                         style={{background: '#f47da7', padding: '10px'}}/>
                    <div>
                      <h4>Lifestyle Drugs Survey</h4>
                      <p>Based on Drug Abuse Screening Test (DAS-10) for potential drug abuse risk.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div id="lifestyle-drugs-summary-results">
                      <div className="text-center">
                        <h4 className="mb-2">Your Score</h4>
                        {!isNullOrUndefined(drugResultStr) ?
                          <h4 style={{color: 'var(--survey-primary)'}}>Abuse:&nbsp;{this.getResultRiskString(drugResultStr)}</h4>
                          : <h4 className='text-secondary'>SURVEY INCOMPLETE</h4>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary btn-lg border rounded" type="button" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getChecksForResult(count) {
    if (count === 0) {
      return null;
    }
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(<i className="fa fa-check family-history-check"/>)
    }
    return (
      arr.map(ios => (ios))
    )
  }

  getResultRiskString(res) {
    if (!res)
      return;
    const result = Number.parseInt(res);
    switch (result) {
      case 0:
        return 'No risk'
        break;
      case 1:
      case 2:
        return 'Low risk'
        break;
      case 3:
      case 4:
      case 5:
        return 'Moderate risk'
        break;
      case 6:
      case 7:
      case 8:
        return 'Substantive risk'
        break;
      case 9:
      case 10:
        return 'Severe risk'
        break;
    }
  }
}

export default SurveySummary;
