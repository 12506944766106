import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../../actions/index'
import GeneticsReportSummary from "./GeneticsReportSummary";

const mapStateToProps = state => ({
  testResults: state.TestResults
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const GeneticsReportSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(GeneticsReportSummary);

export default GeneticsReportSummaryContainer
