import {bfetch} from "../lib/http";
import {
  ADMIN_ATTACH_SWAB_KITS,
  ADMIN_CREATE_SWAB_KITS,
  ADMIN_CREATE_TESTS_RESULTS,
  ADMIN_DELETE_SWAB_KITS,
  ADMIN_DETACH_SWAB_KITS,
  ADMIN_FETCH_ALL_SWAB_KITS,
  ADMIN_FETCH_TESTS_RESULTS,
  ADMIN_FETCH_USER_DETAILS,
  ADMIN_UPDATE_TESTS_RESULTS,
  ADMIN_UPDATE_USER_PROFILE
} from "../lib/constants";
import {doRegisterSwabKitSuccess} from "./swab-kits";

export const fetchAllResults = (filter) => {
  const size = filter && filter.length >= 2 ? '100000' : '200';
  let url = `${ADMIN_FETCH_TESTS_RESULTS}?page=0&size=${size}&sort=lastModifiedDate,desc`;
  if(filter && filter.length >= 2) url = url + '&filter=' + filter;
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(encodeURI(url)).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const fetchAllSwabKits = (filter) => {
  const size = filter && filter.length >= 2 ? '100000' : '200';
  let url = `${ADMIN_FETCH_ALL_SWAB_KITS}?page=0&size=${size}&sort=lastModifiedDate,asc`;
  if(filter && filter.length >= 2) url = url + '&filter=' + filter;
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(encodeURI(url)).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const updateUserTestResults = (modal, sendEmail = false) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_UPDATE_TESTS_RESULTS + '?sendEmail=' + sendEmail,
        {
          method: 'PUT',
          body: modal
        }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const fetchUserDetails = (filter) => {
  return async (dispatch, getState) => {
    const size = filter && filter.length >= 2 ? '100000' : '200';
    let url = `${ADMIN_FETCH_USER_DETAILS}?page=0&size=${size}`;
    if(filter && filter.length >= 2) url = url + '&filter=' + filter;
    return new Promise((resolve, reject) => {
      bfetch(encodeURI(url)).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const detachSwabKit = (testId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_DETACH_SWAB_KITS, {
        method: 'PATCH',
        body: testId
      }).then(res => {
        dispatch(doRegisterSwabKitSuccess(res))
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const attachSwabKit = (swabKitId, userId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_ATTACH_SWAB_KITS, {
        method: 'PATCH',
        body: {swabKitId, userId}
      }).then(res => {
        dispatch(doRegisterSwabKitSuccess(res))
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const updateUserProfileAdmin = (payload) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_UPDATE_USER_PROFILE,
        {
          method: 'PATCH',
          body: payload
        }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const createNewTestAdmin = (payload) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_CREATE_TESTS_RESULTS,
        {
          method: 'POST',
          body: payload
        }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}


export const createSwabKitId = (swabKitId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_CREATE_SWAB_KITS, {
        method: 'POST',
        body: {identifier: swabKitId, isRegistered: false}
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const deleteSwabKitId = (swabKitId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_DELETE_SWAB_KITS + '/' + swabKitId, {
          method: 'DELETE'
        }
      ).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}