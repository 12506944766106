import {applyMiddleware, compose, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducer from './../reducers'
import {createLogger} from 'redux-logger'

function configureStore(initialState) {
  const loggerMiddleware =  createLogger({predicate: (getState, action) => true});

  if(process.env.NODE_ENV === 'production'){
    console.log("Initializing App");
    const enhancer = compose(
      applyMiddleware(
        thunkMiddleware
      )
    );
    return createStore(reducer, initialState, enhancer)
  }else{
    console.log("Initializing App! " + process.env.NODE_ENV);
    const enhancer = compose(
      applyMiddleware(
        thunkMiddleware,
        //loggerMiddleware,
      )
    );
    return createStore(reducer, initialState, enhancer);
  }

}

export const store = configureStore({});
