import React from 'react'
import {Route} from 'react-router-dom'
import AdminHeaderContainer from "../pages/Admin/Header/AdminHeaderContainer";

const DashboardRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      <>
        <div className={'main-website'}>
          <AdminHeaderContainer />
          <Component {...props} />
        </div>
      </>
    )}/>
  )
}

export default DashboardRoute
