import React from "react";
import Helmet from "react-helmet";
import queryString from 'query-string'
import {isNullOrUndefined} from "../../../lib/helper";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      key: undefined,
      password: undefined,
      cPassword: undefined
    }
    const values = queryString.parse(this.props.location.search)
    if (!isNullOrUndefined(values.key)) {
      this.state.key = values.key
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.resetPassword()
  }

  resetPassword() {
    this.setState({error: false})
    if(this.state.password !== this.state.cPassword){
      this.setState({error: true})
      return;
    }
    this.setState({processingLogin: true})
    this.props.resetPassword(this.state.password, this.state.key).then(res => {
      this.setState({processingLogin: false})
      alert('Success, your password has been changed.')
      this.props.history.push('/sign-in')
    }).catch(err => {
      alert('Something went wrong, please try again.')
      this.setState({processingLogin: false})
    })
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Reset Password | HaluGen Life Sciences</title>

          <meta itemProp="name" content="Reset Password | HaluGen Life Sciences"/>
          <meta itemProp="description" content="Reset Password for HaluGen Life Sciences."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description" content="Reset Password for HaluGen Life Sciences."/>
          <meta property="og:title" content="Reset Password | HaluGen Life Sciences"/>
          <meta property="og:description" content="Reset Password for HaluGen Life Sciences."/>
          <meta property="og:url" content="https://www.halugen.com/sign-in.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="Reset Password | HaluGen Life Sciences"/>
          <meta name="twitter:description" content="Reset Password for HaluGen Life Sciences."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        <section id="signin">
          <div className="container">
            <section>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 mx-auto my-4">
                  <h2 className="alt-color text-center mb-5">Reset Password</h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-label-group px-1 pb-3">
                      <input placeholder="New Password" className="form-control" required="required" aria-label="New Password"
                             type="password" name="password" id="password" minLength={6}
                             onChange={(e) => this.setState({password: e.target.value})}/>
                    </div>

                    <div className="form-label-group px-1">
                      <input placeholder="Confirm Password" className="form-control" required="required" aria-label="Confirm Password"
                             type="password" name="cpassword" id="cpassword" minLength={6}
                             onChange={(e) => this.setState({cPassword: e.target.value})}/>
                    </div>

                    {this.state.error ? <div className='text-danger pt-2 px-2'>
                      Passwords do not match.
                    </div> : null}

                    <fieldset name="fsaction" id="fsaction">
                      <div className="actions text-center">
                        <input name="commit" value="Reset Password" type={'submit'} disabled={this.state.processingLogin}
                               className="btn btn-large btn-gradient mt-3" data-disable-with="Reset Password"
                        />
                      </div>
                    </fieldset>

                  </form>
                </div>
              </div>
            </section>
          </div>

        </section>
      </>
    )
  }
}

export default ResetPassword
