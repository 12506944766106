import React, {Component} from 'react';
import {InfoWindow} from 'react-google-maps';

export class PlaceInfoWindow extends Component {
  render() {
    const {description, name, image} = this.props

    return (
      <InfoWindow onCloseClick={this.props.closeWindow}>
        <div>
          <div className='d-flex justify-content-center'>
            <img style={{width: '300px', height: 'auto'}} className='img-fluid mb-3'
                 src={image}/>
          </div>
          <h5 className='mb-2'>{name}</h5>
          <p>{description}</p>
        </div>
      </InfoWindow>
    );
  }
}

export default PlaceInfoWindow;