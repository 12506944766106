import createReducer from "./createReducer";
import * as types from "../actions/types";


export const Surveys = createReducer([], {
  [types.LOGIN_SUCCESS](state, action) {
    let data = action.payload.response.res;
    const customerDetails = action.payload.response.res_cus;
    data = {...data, ...customerDetails}
    if (data.surveys)
      return data.surveys
    else
      return [];
  },
  [types.SAVE_SURVEY_SUCCESS](state, action) {
    var newState = Object.assign([], state);
    const survey = action.payload.response;
    const surveyId = action.payload.response.id;
    newState = newState.filter(res => res.id !== surveyId);
    newState.push(survey)
    return newState
  },
  [types.DELETE_SURVEY_SUCCESS](state, action) {
    var newState = Object.assign([], state);
    const surveyId = action.payload.surveyId;
    newState = newState.filter(res => res.id !== surveyId);
    return newState
  },
  [types.LOGOUT_SUCCESS](state, action) {
    return []
  }
});
