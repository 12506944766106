import React from 'react'
import {Route} from 'react-router-dom'
import FooterDashboard from "../pages/Dashboard/FooterDashboard";
import DashboardHeaderContainer from "../pages/Dashboard/Header/DashboardHeaderContainer";

const DashboardRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      <>
        <div className={'dashboard'}>
          <DashboardHeaderContainer/>
          <Component {...props} />
          <FooterDashboard/>
        </div>
      </>
    )}/>
  )
}

export default DashboardRoute
