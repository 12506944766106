import React from "react";
import {Link} from "react-router-dom";
import {isNullOrUndefined} from "../../../lib/helper";
import {verifyLegalAge} from "../../../lib/verify-age-helper";
import {toast} from "react-toastify";
import Helmet from "react-helmet";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      emailConfirm: undefined,
      password: undefined,
      passwordConfirm: undefined,
      country: 'ca',
      region: undefined,
      fname: undefined,
      lname: undefined,
      month: undefined,
      day: undefined,
      year: undefined,

      loading: false,
      errorMessage: undefined

    }

    if(this.props.userCountry === 'Canada'){
      this.state.country = 'ca';
    }else if(this.props.userCountry === 'United States'){
      this.state.country = 'us';
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Register Kit | HaluGen Life Sciences</title>

          <meta itemProp="name" content="Register Kit | HaluGen Life Sciences"/>
          <meta itemProp="description" content="Register Kit for HaluGen Life Sciences."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description" content="Register Kit for HaluGen Life Sciences."/>
          <meta property="og:title" content="Register Kit | HaluGen Life Sciences"/>
          <meta property="og:description" content="Register Kit for HaluGen Life Sciences."/>
          <meta property="og:url" content="https://www.halugen.com/register"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="Register Kit | HaluGen Life Sciences"/>
          <meta name="twitter:description" content="Register Kit for HaluGen Life Sciences."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  registerUser() {
    this.setState({loading: true, errorMessage: undefined})
    if (this.state.email !== this.state.emailConfirm) {
      this.setState({loading: false, errorMessage: 'Emails do not match.'})
      toast.error('Emails do not match.')
      return;
    }
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({loading: false, errorMessage: 'Passwords do not match.'})
      toast.error('Passwords do not match.')
      return;
    }
    const dob = this.state.month + '-' + this.state.day + '-' + this.state.year;

    if (!verifyLegalAge(this.state.day, this.state.month, this.state.year)) {
      toast.error('You must be 18 to register.')
      this.setState({loading: false, errorMessage: 'You must be 18 to register.'})
      return;
    }

    this.props.registerUser(this.state.email, this.state.password, this.state.fname, this.state.lname, this.state.country, this.state.region, dob,
      this.state.gender, true).then(res => {
      this.setState({loading: false, errorMessage: undefined})
      this.props.history.push('/dashboard/overview')
    }).catch(err => {
      console.error(err)
      this.setState({loading: false, errorMessage: err.title})
      toast.error(err.title)
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.registerUser();
    return;
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        <section id="registerkit" className="auth register">
          <div className="container">


            <div className="row pt-5 pt-md-3">
              <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 mx-auto my-4">
                <h2 className="alt-color text-center mb-5">Create your account</h2>

                <form className="text-left" onSubmit={this.handleSubmit}>

                  <div className="form-label-group mb-3">
                    <input placeholder="First name" className="form-control" required="required"
                           aria-label="Confirm First Name" type="text" name="user[fname]" id="user_fname"
                           pattern="[A-Za-z '-]{2,}"
                           title="Should not contain numbers or special characters & minimum two characters."
                           value={this.state.fname} onChange={(e) => this.setState({fname: e.target.value})}/>
                    <div className="invalid-feedback">A valid email is required</div>
                  </div>
                  <div className="form-label-group mb-3">
                    <input placeholder="Last name" className="form-control" required="required"
                           aria-label="Confirm Last Name" type="text" name="user[lname]" id="user_lname"
                           pattern="[A-Za-z '-]{2,}"
                           title="Should not contain numbers or special characters & minimum two characters."
                           value={this.state.lname} onChange={(e) => this.setState({lname: e.target.value})}/>
                    <div className="invalid-feedback">A valid email is required</div>
                  </div>
                  <div className="form-label-group lobo-form-col2">
                    <div className='pb-2'>Country</div>
                    <div id="countryselect">
                      <div className='row'>
                        <div className='col-6'>
                          <select id="country" className="form-control w-100" name="country_code"
                                  value={this.state.country} onChange={(e) => this.setState({country: e.target.value})}>
                            <option value="ca" selected="">Canada</option>
                            <option value="us" selected="">United States</option>
                          </select>
                        </div>
                        <div className='col-6'>
                          {this.showRegionDropDown('ca') ?
                          <select id="region" className="form-control w-100"
                                  name="caDropDown"
                                  data-blank="No regions" data-ca="Province" data-us="State" required="required"
                                  value={this.state.region} onChange={(e) => this.setState({region: e.target.value})}>

                            <option value="">Province</option>
                            <option value="ontario" data-country="ca">Ontario
                            </option>
                            <option value="alberta" data-country="ca">Alberta
                            </option>
                            <option value="british_columbia" data-country="ca">British
                              Columbia
                            </option>
                            <option value="saskatchewan" data-country="ca">
                              Saskatchewan
                            </option>
                            <option value="manitoba" data-country="ca">Manitoba
                            </option>
                            <option value="quebec" data-country="ca">Quebec
                            </option>
                            <option value="newfoundland_and_labrador" data-country="ca">
                              Newfoundland and Labrador
                            </option>
                            <option value="new_brunswick" data-country="ca">New
                              Brunswick
                            </option>
                            <option value="nova_scotia" data-country="ca">Nova
                              Scotia
                            </option>
                            <option value="price_edward_island" data-country="ca">Prince
                              Edward Island
                            </option>
                          </select> : null }

                          {this.showRegionDropDown('us') ?
                          <select id="region" className="form-control w-100"
                                  name="usDropDown"
                                  data-blank="No regions" data-ca="Province" data-us="State" required="required"
                                  value={this.state.region} onChange={(e) => this.setState({region: e.target.value})}>
                            <option value="">State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select> : null }
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="form-label-group lobo-form-date my-3">
                    <div className='pb-2'>Enter your date of birth to
                      confirm you are of legal age
                    </div>
                    <div>
                      <div className='row'>
                        <div className='col-4'>
                          <select id="user_user_profile_attributes_date_of_birth_2i"
                                  value={this.state.month} onChange={(e) => this.setState({month: e.target.value})}
                                  name="user[user_profile_attributes][date_of_birth(2i)]" className="form-control w-100"

                                  required="required">
                            <option value="">Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                        <div className='col-4'>
                          <select id="user_user_profile_attributes_date_of_birth_3i"
                                  value={this.state.day} onChange={(e) => this.setState({day: e.target.value})}
                                  name="user[user_profile_attributes][date_of_birth(3i)]" className="form-control w-100"
                                  required="required">
                            <option value="">Day</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </select>
                        </div>
                        <div className='col-4'>
                          <select id="user_user_profile_attributes_date_of_birth_1i"
                                  value={this.state.year} onChange={(e) => this.setState({year: e.target.value})}
                                  name="user[user_profile_attributes][date_of_birth(1i)]" className="form-control w-100"
                                  required="required">
                            <option value="">Year</option>
                            <option value="2003">2003</option>
                            <option value="2002">2002</option>
                            <option value="2001">2001</option>
                            <option value="2000">2000</option>
                            <option value="1999">1999</option>
                            <option value="1998">1998</option>
                            <option value="1997">1997</option>
                            <option value="1996">1996</option>
                            <option value="1995">1995</option>
                            <option value="1994">1994</option>
                            <option value="1993">1993</option>
                            <option value="1992">1992</option>
                            <option value="1991">1991</option>
                            <option value="1990">1990</option>
                            <option value="1989">1989</option>
                            <option value="1988">1988</option>
                            <option value="1987">1987</option>
                            <option value="1986">1986</option>
                            <option value="1985">1985</option>
                            <option value="1984">1984</option>
                            <option value="1983">1983</option>
                            <option value="1982">1982</option>
                            <option value="1981">1981</option>
                            <option value="1980">1980</option>
                            <option value="1979">1979</option>
                            <option value="1978">1978</option>
                            <option value="1977">1977</option>
                            <option value="1976">1976</option>
                            <option value="1975">1975</option>
                            <option value="1974">1974</option>
                            <option value="1973">1973</option>
                            <option value="1972">1972</option>
                            <option value="1971">1971</option>
                            <option value="1970">1970</option>
                            <option value="1969">1969</option>
                            <option value="1968">1968</option>
                            <option value="1967">1967</option>
                            <option value="1966">1966</option>
                            <option value="1965">1965</option>
                            <option value="1964">1964</option>
                            <option value="1963">1963</option>
                            <option value="1962">1962</option>
                            <option value="1961">1961</option>
                            <option value="1960">1960</option>
                            <option value="1959">1959</option>
                            <option value="1958">1958</option>
                            <option value="1957">1957</option>
                            <option value="1956">1956</option>
                            <option value="1955">1955</option>
                            <option value="1954">1954</option>
                            <option value="1953">1953</option>
                            <option value="1952">1952</option>
                            <option value="1951">1951</option>
                            <option value="1950">1950</option>
                            <option value="1949">1949</option>
                            <option value="1948">1948</option>
                            <option value="1947">1947</option>
                            <option value="1946">1946</option>
                            <option value="1945">1945</option>
                            <option value="1944">1944</option>
                            <option value="1943">1943</option>
                            <option value="1942">1942</option>
                            <option value="1941">1941</option>
                            <option value="1940">1940</option>
                            <option value="1939">1939</option>
                            <option value="1938">1938</option>
                            <option value="1937">1937</option>
                            <option value="1936">1936</option>
                            <option value="1935">1935</option>
                            <option value="1934">1934</option>
                            <option value="1933">1933</option>
                            <option value="1932">1932</option>
                            <option value="1931">1931</option>
                            <option value="1930">1930</option>
                            <option value="1929">1929</option>
                            <option value="1928">1928</option>
                            <option value="1927">1927</option>
                            <option value="1926">1926</option>
                            <option value="1925">1925</option>
                            <option value="1924">1924</option>
                            <option value="1923">1923</option>
                            <option value="1922">1922</option>
                            <option value="1921">1921</option>
                            <option value="1920">1920</option>
                            <option value="1919">1919</option>
                            <option value="1918">1918</option>
                            <option value="1917">1917</option>
                            <option value="1916">1916</option>
                            <option value="1915">1915</option>
                            <option value="1914">1914</option>
                            <option value="1913">1913</option>
                            <option value="1912">1912</option>
                            <option value="1911">1911</option>
                            <option value="1910">1910</option>
                            <option value="1909">1909</option>
                            <option value="1908">1908</option>
                            <option value="1907">1907</option>
                            <option value="1906">1906</option>
                            <option value="1905">1905</option>
                            <option value="1904">1904</option>
                            <option value="1903">1903</option>
                            <option value="1902">1902</option>
                            <option value="1901">1901</option>
                            <option value="1900">1900</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="invalid-feedback">You must be over 19.</div>
                  </div>

                  <div className="form-row">
                    <div className="col-12">
                      <div className="form-group">
                        <select value={this.state.gender} className="form-control" name="genderForm" required="required"
                                onChange={(e) => this.setState({gender: e.target.value})}>
                          <option value="" selected>Gender</option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="OTHER">Other</option>
                        </select></div>
                    </div>
                  </div>


                  <div className="form-label-group mb-3">
                    <input placeholder="Email" autoComplete="email" className="form-control" required="required"
                           value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}
                           aria-label="Email" type="email" name="user[email]" id="user_email"/>
                    <div className="invalid-feedback">A valid email is required</div>
                  </div>

                  <div className="form-label-group mb-3">
                    <input placeholder="Confirm Email" autoComplete="email" className="form-control" required="required"
                           value={this.state.emailConfirm}
                           onChange={(e) => this.setState({emailConfirm: e.target.value})}
                           aria-label="Confirm Email" type="email" name="user[confirm_email]" id="user_confirm_email"/>
                    <div className="invalid-feedback">A valid email is required</div>
                  </div>

                  <div className="form-label-group mb-3">
                    <input placeholder="Password" autoComplete="new password" className="form-control"
                           value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}
                           required="required" aria-label="Password" type="password" name="user[password]" minLength={6}
                           maxLength={99}
                           id="user_password"/>
                    <div className="invalid-feedback">A valid password is required</div>
                  </div>

                  <div className="form-label-group mb-3">
                    <input placeholder="Confirm password" autoComplete="password" className="form-control"
                           value={this.state.passwordConfirm}
                           onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                           required="required" aria-label="Confirm password" type="password" minLength={6}
                           maxLength={99}
                           name="user[password_confirmation]" id="user_password_confirmation"/>
                    <div className="invalid-feedback">The passwords need to match</div>
                  </div>

                  {!isNullOrUndefined(this.state.errorMessage) ?
                    <div className='text-danger'>
                      {this.state.errorMessage}
                    </div>
                    : null}

                  <p className="text-center mt-3">By continuing you agree to the <Link
                    to="/terms-of-service">Terms of Service</Link> and <Link
                    to="/privacy">Privacy Statement</Link>. You can adjust
                    communication preferences at any time.</p>


                  <fieldset name="fsaction" id="fsaction">
                    <div className="actions text-center">
                      {!this.state.loading ?
                        <input type="submit" name="commit" value="Create account"
                               className="btn btn-large btn-gradient mt-3" data-disable-with="Create account"/>
                        :
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>}
                    </div>
                  </fieldset>

                </form>
                <p className="text-center mt-3">Already have an account? <Link
                  to="/sign-in">Sign in</Link></p>
              </div>

            </div>
          </div>
        </section>
      </>
    )
  }


  showRegionDropDown(type) {
    if (this.state.country === 'ca') {
      if (type !== 'ca') {
        return false
      }
    } else if (this.state.country === 'us') {
      if (type !== 'us') {
        return false
      }
    }

    return true;
  }
}

export default Register
