export const SAVE_SURVEY_SUCCESS = 'SAVE_SURVEY_SUCCESS'
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS'
export const STARTUP_BEGIN = 'STARTUP_BEGIN'
export const CUSTOMER_DETAILS_UPDATE = 'CUSTOMER_DETAILS_UPDATE'
export const PASSWORD_PROTECTED_PAGE = 'PASSWORD_PROTECTED_PAGE'
export const REGISTER_SWAB_KIT = 'REGISTER_SWAB_KIT'
export const FETCH_SHOPIFY_PRODUCT_SUCCESS = 'FETCH_SHOPIFY_PRODUCT_SUCCESS'
export const FETCH_SHOPIFY_PRODUCT_FAILURE = 'FETCH_SHOPIFY_PRODUCT_FAILURE'

export const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SET_USER_COUNTRY = 'SET_USER_COUNTRY';
export const SAVE_USER_ACCESS_TOKEN = 'SAVE_USER_ACCESS_TOKEN';


// Login Part
export const LOGIN_BEGIN   = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// Login Part
export const AUTO_LOGIN_BEGIN   = 'AUTO_LOGIN_BEGIN';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const AUTO_LOGIN_FAILURE = 'AUTO_LOGIN_FAILURE';

export const AGE_GATE_VERIFICATION_SUCCESS   = 'AGE_GATE_VERIFICATION_SUCCESS';

// Registration Part
export const REGISTER_USER_BEGIN   = 'REGISTER_BEGIN';
export const REGISTER_USER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_USER_END = 'REGISTER_USER_END';

export const ADD_TO_CART = 'ADD_TO_CART';
export const EMPTY_CART = 'EMPTY_CART';

export const SAVE_AFFILIATE_REF = 'SAVE_AFFILIATE_REF';
export const SAVE_DISCOUNT_CODE = 'SAVE_DISCOUNT_CODE';


export const NAVIGATE_TO_LOGIN_SCREEN = 'NAVIGATE_TO_LOGIN_SCREEN';
