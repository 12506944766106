import React from 'react'
import {Route} from 'react-router-dom'
import HeaderContainer from "../components/Header/HeaderContainer";
import Footer from "../components/Footer/Footer";

const MainWebsiteRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      <div className={'main-website'}>
        <HeaderContainer/>
        <Component {...props} />
        <Footer/>
      </div>
    )}/>
  )
}

export default MainWebsiteRoute
