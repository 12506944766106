import createReducer from './createReducer'
import * as types from '../actions/types'
import {isNullOrUndefined, isNullOrUndefinedOrEmpty} from '../lib/helper'
import {STORAGE_AUTH_TOKEN_KEY} from "../lib/constants";
import jwtDecode from 'jwt-decode';
import {localStorageGet, localStorageRemove, localStorageSet} from "../lib/localStorage-helper";


function getLoginStatusObject() {
  const userProfile = localStorageGet('HALUGEN_user-profile')
  const token = fetchToken();

  if (Object.keys(token).length === 0) {
    localStorageRemove('HALUGEN_user-profile')
    return {
      loginFailureAttemptCount: 0,
      loginSuccess: false,
      loginFailure: false,
      isLoggedIn: false,
      email: undefined,
      loading: false,
      userProfile: {},
      error: null
    }
  } else {
    return {
      loginFailureAttemptCount: 0,
      loginSuccess: !isNullOrUndefined(userProfile) && userProfile !== 'null' && userProfile !== 'undefined' ? true : false,
      loginFailure: false,
      isLoggedIn: !isNullOrUndefined(userProfile) && userProfile !== 'null' && userProfile !== 'undefined' ? true : false,
      email: !isNullOrUndefined(userProfile) && userProfile !== 'null' && userProfile !== 'undefined' ? JSON.parse(userProfile).email : undefined,
      loading: false,
      userProfile: !isNullOrUndefined(userProfile) && userProfile !== 'null' && userProfile !== 'undefined' ? JSON.parse(userProfile) : {},
      error: null
    }
  }
}

/** LoginStatus */
export const LoginStatus = createReducer(getLoginStatusObject(), {
  [types.LOGIN_BEGIN](state, action) {
    return {
      ...state,
      loading: true,
      isLoggedIn: false,
      email: action.payload.email
    }
  },
  [types.LOGIN_SUCCESS](state, action) {
    localStorageSet('HALUGEN_token', action.payload.response.res_jwt.id_token);
    let data = action.payload.response.res;
    const customerDetails = action.payload.response.res_cus;
    data = {...data, ...customerDetails}
    localStorageSet('HALUGEN_user-profile', JSON.stringify(data));
    return {
      ...state,
      loading: false,
      loginSuccess: true,
      isLoggedIn: true,
      loginFailure: false,
      userProfile: data,
      email: data.email,
    }
  },
  [types.AUTO_LOGIN_SUCCESS](state, action) {
    localStorageSet('HALUGEN_user-profile', JSON.stringify(action.payload.response.data));
    return {
      ...state,
      loading: false,
      loginSuccess: true,
      isLoggedIn: true,
      loginFailure: false,
      userProfile: action.payload.response.data,
      email: action.payload.response.data.email
    }
  },
  [types.REGISTER_USER_SUCCESS](state, action) {
    localStorageSet('HALUGEN_user-profile', JSON.stringify({
      email: action.payload.payloadObj.email, birth_date: action.payload.payloadObj.birth_date,
      country: action.payload.payloadObj.country, region: action.payload.payloadObj.region
    }));
    localStorageSet('HALUGEN_token', action.payload.res.jwt);

    return {
      ...state,
      loading: false,
      loginSuccess: true,
      isLoggedIn: true,
      loginFailure: false,
      userProfile: {
        email: action.payload.payloadObj.email, birth_date: action.payload.payloadObj.birth_date,
        country: action.payload.payloadObj.country, region: action.payload.payloadObj.region
      },
      email: action.payload.payloadObj.email
    }
  },
  [types.AUTO_LOGIN_FAILURE](state, action) {
    localStorageRemove('HALUGEN_token');
    localStorageRemove('HALUGEN_user-profile');
    return {
      ...state,
      loading: false,
      error: action.payload,
      loginSuccess: false,
      isLoggedIn: false,
      loginFailure: true,
      loginFailureAttemptCount: state.loginFailureAttemptCount + 1
    }
  },
  [types.LOGIN_FAILURE](state, action) {
    localStorageRemove('HALUGEN_token');
    localStorageRemove('HALUGEN_user-profile');
    return {
      ...state,
      loading: false,
      error: action.payload,
      loginSuccess: false,
      isLoggedIn: false,
      loginFailure: true,
      loginFailureAttemptCount: state.loginFailureAttemptCount + 1
    }
  },
  [types.LOGOUT_SUCCESS](state, action) {
    localStorageRemove('HALUGEN_token');
    localStorageRemove('HALUGEN_user-profile');
    localStorageRemove('HALUGEN_cart');
    localStorageRemove(STORAGE_AUTH_TOKEN_KEY);
    return {
      ...state,
      loading: false,
      loginSuccess: false,
      loginFailure: false,
      isLoggedIn: false,
      userProfile: {},
      loginFailureAttemptCount: 0,
      email: undefined
    }
  },
  [types.LOGOUT_FAILURE](state, action) {
    localStorageRemove('HALUGEN_token');
    localStorageRemove('HALUGEN_user-profile');
    return {
      ...state,
      loading: false,
      error: action.payload
    }
  },
  [types.CUSTOMER_DETAILS_UPDATE](state, action) {
    var newState = Object.assign({}, state);
    newState.userProfile.fname = action.res.res.fname;
    newState.userProfile.lname = action.res.res.lname;
    newState.userProfile.gender = action.res.res.gender;
    newState.userProfile.birth_date = action.res.res.dob;
    newState.userProfile.region = action.res.res.region;
    newState.userProfile.country = action.res.res.country;
    localStorageSet('HALUGEN_user-profile', JSON.stringify(newState.userProfile));
    return newState;
  }
});

function getCountry() {
  const country = localStorageGet('HALUGEN_user-country')

  if (!isNullOrUndefinedOrEmpty(country)) {
    return country
  } else {
    return undefined;
  }
}

export const UserCountry = createReducer({
  country: getCountry()
}, {

  [types.SET_USER_COUNTRY](state, action) {
    var newState = Object.assign({}, state);
    newState.country = action.payload;
    localStorageSet('HALUGEN_user-country', action.payload);
    return newState;
  }
});


function fetchToken() {

  let token = localStorageGet(STORAGE_AUTH_TOKEN_KEY);
  try {
    if (!isNullOrUndefined(token)) {
      const jwtExpiry = jwtDecode(JSON.parse(token).id_token).exp;

      if (jwtExpiry * 1000 < (new Date()).getTime()) {
        return {};
      } else {
        return JSON.parse(token).id_token;
      }
    } else {
      return {}
    }
  } catch (e) {
    return {}
  }
}

export const UserAccessToken = createReducer(fetchToken(), {
  [types.SAVE_USER_ACCESS_TOKEN](state, action) {
    return action.data.id_token;
  },
  [types.LOGOUT_SUCCESS](state, action) {
    localStorageRemove(STORAGE_AUTH_TOKEN_KEY)
    return {}
  },
  [types.LOGIN_FAILURE](state, action) {
    return {}
  }
});

export const NavigateToLogin = createReducer({flag: false}, {
  [types.NAVIGATE_TO_LOGIN_SCREEN](state, action) {
    return {flag: !state.flag}
  }
})

export const PasswordProtectedPages = createReducer({isVisible: false}, {
  [types.PASSWORD_PROTECTED_PAGE](state, action) {
    return {isVisible: true}
  }
})
