import React from "react";
import './Footer-Dark.scss'
import {Link} from "react-router-dom";
import GeneticsReportSummaryContainer from "./Summary/GeneticsReportSummaryContainer";
import SurveySummaryContainer from "./Summary/SurveySummaryContainer";

class FooterDashboard extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className='dashboard dashboard-footer'>
        <div id="genetics-modals">
          <GeneticsReportSummaryContainer/>
        </div>
        <div id="survey-modals">
          <div id="summary-survey-group">
            <SurveySummaryContainer/>
          </div>
        </div>
        <div className="bg-primary footer-dark">
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-3 item">
                  <h3>Dashboard</h3>
                  <ul>
                    <li><Link id="overview-tab-js-footer-link" to={'/dashboard/overview'}>Overview</Link></li>
                    <li><Link id="genetics-tab-js-footer-link" to={'/dashboard/genetic'}>Genetic Reports</Link></li>
                    <li><Link id="surveys-tab-js-footer-link" to={'/dashboard/survey'}>Surveys</Link></li>
                    <li><Link id="research-papers-tab-js-footer-link" to={'/dashboard/research'}>Research Papers</Link></li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-3 item">
                  <h3>Quick Links</h3>
                  <ul>
                    <li><Link data-toggle="modal" data-target="#summary-genetics-modal1" to="">Genetics Report</Link></li>
                    <li><Link data-toggle="modal" data-target="#summary-surveys-modal1" to="">Surveys Report</Link></li>
                    <li><a href={require('../../assets/uploads/HaluGen - Psychedelics and Genetic Pre-Screening White Paper.pdf')} target="_blank">White Paper</a></li>
                  </ul>
                </div>
                <div className="col-md-6 item text">
                  <h3>HaluGen Life Sciences Inc.</h3>
                  <p className='text-white'>Our mission is to help people make informed choices around psychedelic assisted therapy through
                    personalization with genetics, data
                    and engagement.</p>
                </div>
                <div className=" col item social">
                  <a href="https://www.facebook.com/halugensci" target="_blank">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="https://twitter.com/halugensci" target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="https://www.instagram.com/halugensci" target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/halugensci" target="_blank">
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a href="mailto:support@halugen.com" target="_blank">
                    <i className="fa fa-envelope"></i>
                  </a>
                </div>
              </div>
              <p className="copyright text-white">Your use of our services and the information provided through this website, including test results
                and
                reports,
                is
                for informational purposes only, and is not intended for medical advice or treatment, or for other therapeutic or diagnostic use.
                Before
                taking any action based on this information, you must consult an experienced healthcare professional. Your use of our services and
                this
                website is governed by the laws of the Province of Ontario and the federal laws of Canada. © 2022 HaluGen Life Sciences. All rights
                reserved.</p>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default FooterDashboard;
