import React from "react";
import './AboutUs.scss'
import john from '../../assets/images/john-team-img-370x370.jpg'
import franco from '../../assets/images/franco-team-img-370x370.jpg'
import roger from '../../assets/images/roger-team-img-370x370.jpg'
import hemanshu from '../../assets/images/hemanshu-team-img-370x370.jpg'
import Helmet from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);

  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>About Us | HaluGen Life Sciences</title>

          <meta itemProp="name" content="About Us | HaluGen Life Sciences"/>
          <meta itemProp="description"
                content="HaluGen Life Sciences ensures safer psychedelic experiences through DNA testing and personalized results."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description" content="Lobo Genetics ensures a safer cannabis experience through DNA tests and personalized results."/>
          <meta property="og:title" content="About Us | HaluGen Life Sciences"/>
          <meta property="og:description"
                content="HaluGen Life Sciences ensures safer psychedelic experiences through DNA testing and personalized results."/>
          <meta property="og:url" content="https://www.halugen.com/team.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="About Us | HaluGen Life Sciences"/>
          <meta name="twitter:description"
                content="HaluGen Life Sciences ensures safer psychedelic experiences through DNA testing and personalized results."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        <section className="bg-img-team mt-0">
          <div className="bg-overlay gradient-bg1 opacity-9"></div>
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="title text-center">
                  <p className="text-white mb-2">WE ARE HALUGEN</p>
                  <h2 className="text-white">Our mission is to enable safer psychedelic experiences through genetics.</h2>

                </div>
              </div>
            </div>
          </div>
        </section>



        <section id="team">
          <div className="container">
            <div className="col-md-12 text-center wow fadeIn" style={{visibility: 'visible', animationName: 'fadeIn'}}>
              <div className="title d-inline-block">
                <h2 className="gradient-text1 mb-md-3">A personalized psychedelics future</h2>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 order-md-2 wow fadeInRight">
                <div className="card-content-text">
                  <h2>Your journey is unique.</h2>
                  <ul className="card-text">
                    <li>HaluGen Life Sciences is a Toronto-based healthcare technology company founded by a dedicated team of technologists, scientists and entrepreneurs
                      with the goal of helping people make informed choices around psychedelic assisted therapy through personalization.</li>
                    <li>We believe in helping you better understand your response to psychedelics through genetics, data and engagement.
                      Through our proprietary pre-screening platform, users can gain rapid genetic insights from a simple cheek swab.</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-content-image wow hover-effect fadeInLeft">
                  <img src={require('../../assets/images/halugen-card-logo.png')} srcSet={require('../../assets/images/halugen-card-logo-2x.png')} focusable="false" role="img" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*
        <section id="team">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" animateOnce={true}  className="row ">
              <div className="col-md-12 text-center">
                <div className="title d-inline-block">
                  <h6 className="mb-3">OUR PEOPLE</h6>
                  <h2 className="gradient-text1 mb-3">Meet Our Leadership Team</h2>
                  <p className="mb-4">HaluGen Life Sciences is a Vancouver-based healthcare technology company founded by a dedicated team of
                    technologists, scientists and entrepreneurs
                    with the goal of helping people make informed choices around psychedelic assisted therapy through personalization.</p>
                  <p>We believe in helping you better understand your response to psychedelics through genetics, data and engagement.
                    Through our proprietary pre-screening platform, users can gain rapid genetic insights from a simple cheek swab.</p>
                </div>
              </div>
            </ScrollAnimation>


              <div className="row mt-5">
                <div className="col-sm-12">
                  <ScrollAnimation animateIn="fadeInUpCardAbout" animateOnce={true}  id="team-slider-two" className="row wow ">

                    <div className="team-box-two item col-12 col-lg-6 px-sm-0">

                      <ul className="team-two-block left d-flex align-items-center">
                        <li>

                          <div className="team-image">
                            <img className='img-fluid w-100' src={franco} alt="image"/>
                          </div>
                        </li>
                        <li>

                          <div className="team-two-content">
                            <h4>Franco Vigile</h4>
                            <span className="alt-font gradient-text1">Founder</span>
                            <p>Entrepreneur with 10 years experience in operations and finance.
                              Founder of GENNCO Holdings, a boutique advisory firm.</p>
                          </div>
                        </li>
                      </ul>

                      <ul className="team-two-block right d-flex align-items-center">
                        <li className="order-sm-2">

                          <div className="team-image">
                            <img className='img-fluid w-100' src={john} alt="image"/>
                          </div>
                        </li>
                        <li>

                          <div className="team-two-content">
                            <h4>John Lem</h4>
                            <span className="alt-font gradient-text1">Business Development</span>
                            <p>Co-founder of Spartan Bioscience and Lobo Genetics with 15 years experience in life sciences, medical devices and
                              capital
                              markets.</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="team-box-two item col-12 col-lg-6 px-0">

                      <ul className="team-two-block left d-flex align-items-center">
                        <li>

                          <div className="team-image">
                            <img className='img-fluid w-100' src={hemanshu} alt="image"/>
                          </div>
                        </li>
                        <li>

                          <div className="team-two-content">
                            <h4>Hemanshu Modi</h4>
                            <span className="alt-font gradient-text1">VP of Operations</span>
                            <p>20 years of experience in life sciences and operations in various senior roles including Luminex, AGCT and Lobo
                              Genetics.</p>
                          </div>
                        </li>
                      </ul>

                      <ul className="team-two-block right d-flex align-items-center">
                        <li className="order-sm-2">

                          <div className="team-image">
                            <img className='img-fluid w-100' src={roger} alt="image"/>
                          </div>
                        </li>
                        <li>

                          <div className="team-two-content">
                            <h4>Roger Hudson</h4>
                            <span className="alt-font gradient-text1">Scientific Lead</span>
                            <p>10 years of experience in research, advocacy and clinical.
                              Neuroscience PhDc & Vanier Scholar in the Addiction Research Group at UWO.</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
          </div>
        </section>
        */}
      </>
    )
  }

}

export default AboutUs
