import {isNullOrUndefined} from "./helper";

export function getBipolarResultStr(result) {
  if (isNullOrUndefined(result))
    return;

  result = Number.parseInt(result);

  if (result >= 0 && result <= 9) {
    return 'No symptoms'
  } else if (result >= 10 && result <= 15) {
    return 'Possible mild symptoms'
  } else if (result >= 16 && result <= 24) {
    return 'Mild symptoms'
  } else if (result >= 25 && result <= 35) {
    return 'Likely symptoms'
  } else if (result >= 36 && result <= 53) {
    return 'Moderate to severe symptoms'
  } else if (result >= 54) {
    return 'Serious symptoms'
  }
}
