import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../actions/index'
import Cart from "./Cart";

const mapStateToProps = state => ({
  product: state.Product,
  cart: state.Cart,
  affiliateRef: state.AffiliateRef,
  discounts: state.Discounts
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const CartContainer = connect(mapStateToProps, mapDispatchToProps)(Cart);

export default CartContainer
