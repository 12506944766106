import createReducer from "./createReducer";
import * as types from "../actions/types";

export const Product = createReducer({}, {
  [types.FETCH_SHOPIFY_PRODUCT_SUCCESS](state, action) {
    var newState = Object.assign({}, state);
    action.data.data.products.edges.forEach(product => {
      //for Canada and USA product id's
      if (product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwMTcxNjUzMjg1Mzc=' ||
        product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njg2MzE2MzgxNjY=') {
        newState = product.node;
      }
    })
    return newState
  },
  [types.FETCH_SHOPIFY_PRODUCT_FAILURE](state, action) {
    var newState = Object.assign({}, state);
    newState = {
      "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwMTcxNjUzMjg1Mzc=",
      "availableForSale": false,
      "descriptionHtml": "<p>Your psychedelics genetic profile with personalized reports and actionable insights, delivered directly to your smartphone.</p>\n<ul>\n<li>\n<strong>Classical Psychedelics:</strong> Understand your sensitivity to classical psychedelics and serotonin response</li>\n<li>\n<strong>Ketamine Metabolism:</strong> Discover your ketamine response based on genetics and format</li>\n<li>\n<strong>Mental Health Risk:</strong> Explore your short- and long-term risk factors associated with psychedelics use</li>\n<li>\n<strong>Future Updates:</strong> Latest research and studies, recommendations and exclusive offers</li>\n</ul>\n<style><!--\n.tb_button {padding:1px;cursor:pointer;border-right: 1px solid #8b8b8b;border-left: 1px solid #FFF;border-bottom: 1px solid #fff;}.tb_button.hover {borer:2px outset #def; background-color: #f8f8f8 !important;}.ws_toolbar {z-index:100000} .ws_toolbar .ws_tb_btn {cursor:pointer;border:1px solid #555;padding:3px}   .tb_highlight{background-color:yellow} .tb_hide {visibility:hidden} .ws_toolbar img {padding:2px;margin:0px}\n--></style>",
      "onlineStoreUrl": null,
      "productType": "Test Kits",
      "title": "Psychedelics Genetic Test",
      "description": "Your psychedelics genetic profile with personalized reports and actionable insights, delivered directly to your smartphone. Classical Psychedelics: Understand your sensitivity to classical psychedelics and serotonin response Ketamine Metabolism: Discover your ketamine response based on genetics and format Mental Health Risk: Explore your short- and long-term risk factors associated with psychedelics use Future Updates: Latest research and studies, recommendations and exclusive offers .tb_button {padding:1px;cursor:pointer;border-right: 1px solid #8b8b8b;border-left: 1px solid #FFF;border-bottom: 1px solid #fff;}.tb_button.hover {borer:2px outset #def; background-color: #f8f8f8 !important;}.ws_toolbar {z-index:100000} .ws_toolbar .ws_tb_btn {cursor:pointer;border:1px solid #555;padding:3px} .tb_highlight{background-color:yellow} .tb_hide {visibility:hidden} .ws_toolbar img {padding:2px;margin:0px}",
      "handle": "test-product-for-developer-use",
      "publishedAt": "2020-12-16T08:23:41Z",
      "variants": {
        "edges": [
          {
            "node": {
              "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzQ5NTU1MTEzMTgwMQ=="
            }
          }
        ]
      },
      "priceRange": {
        "maxVariantPrice": {
          "amount": "89.0",
          "currencyCode": "CAD"
        },
        "minVariantPrice": {
          "amount": "89.0",
          "currencyCode": "CAD"
        }
      },
      "images": {
        "edges": [
          {
            "node": {
              "altText": null,
              "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0SW1hZ2UvMjUzNjQ2NjIxNTc0NjU=",
              "originalSrc": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-front.jpg?v=1613423689",
              "src": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-front.jpg?v=1613423689",
              "transformedSrc": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-front.jpg?v=1613423689"
            }
          },
          {
            "node": {
              "altText": null,
              "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0SW1hZ2UvMjU0ODcwMzEyMzg4MDk=",
              "originalSrc": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-table.jpg?v=1613423695",
              "src": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-table.jpg?v=1613423695",
              "transformedSrc": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-table.jpg?v=1613423695"
            }
          },
          {
            "node": {
              "altText": null,
              "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0SW1hZ2UvMjU0ODcwMzIzODU2ODk=",
              "originalSrc": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-side.jpg?v=1613423699",
              "src": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-side.jpg?v=1613423699",
              "transformedSrc": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-side.jpg?v=1613423699"
            }
          },
          {
            "node": {
              "altText": null,
              "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0SW1hZ2UvMjU0ODcwMzMzNjg3Mjk=",
              "originalSrc": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-back.jpg?v=1613423704",
              "src": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-back.jpg?v=1613423704",
              "transformedSrc": "https://cdn.shopify.com/s/files/1/0516/5147/7657/products/img-kit-back.jpg?v=1613423704"
            }
          }
        ]
      }
    }
    return newState
  },
});

export const WholeSalerProducts = createReducer({}, {
  [types.FETCH_SHOPIFY_PRODUCT_SUCCESS](state, action) {
    var newState = Object.assign({}, state);
    let hasProductFlag = false;
    action.data.data.products.edges.forEach(product => {
      //for Canada and USA product id's
      if (product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY4Nzk4MjA4MDgzNDU=' ||
        product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY5NjUyNTk1MzQ0ODY=') {
        newState = product.node;
        hasProductFlag = true;
      }
    })
    if (!hasProductFlag) {
      newState = {};
    }
    return newState;
  },
  [types.FETCH_SHOPIFY_PRODUCT_FAILURE](state, action) {
    var newState = Object.assign({}, state);
    return newState;
  },
});
