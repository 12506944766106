/* eslint-disable */
import React, {createRef} from 'react'
import './Home.scss'
import LazyLoad from 'react-lazyload';
import img1 from '../../assets/images/iphone-img.png'
import img2 from '../../assets/images/halugen-iphone-slide1.jpg'
import img3 from '../../assets/images/halugen-iphone-slide2.jpg'
import img4 from '../../assets/images/halugen-iphone-slide3.jpg'
import dna from '../../assets/images/test-kit-half-block.png'
import geneticReports from '../../assets/images/genetic-reports-slider-2x.jpg'
import lifestyleSurveys from '../../assets/images/survey-reports-slider-2x.jpg'
import educationalResources from '../../assets/images/educational-resources-slider-2x.jpg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CountUp from "react-countup";
import Hero from "./Hero/Hero";
import ExploreKitCTA from "../../components/Shared/ExploreKitCTA";
import {Link} from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import {isMobile} from 'react-device-detect'
import Helmet from "react-helmet";

class Home extends React.Component {
  scrollToAboutSection = createRef()
  scrollToAboutSectionMobile = createRef()

  scrollSmoothHandler = () => {
    if (isMobile)
      this.scrollToAboutSectionMobile.current.scrollIntoView({behavior: 'smooth'})
    else
      this.scrollToAboutSection.current.scrollIntoView({behavior: 'smooth'})
  }

  constructor(props) {
    super(props);

    this.scrollToAbout = this.scrollToAbout.bind(this);

  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>HaluGen Life Sciences | DNA Testing For Psychedelic Assisted Therapy</title>


          <meta itemProp="name" content="HaluGen Life Sciences | DNA Testing For Psychedelic Assisted Therapy" data-react-helmet="true"/>
          <meta itemProp="description" content="Our genetic-based psychedelics pre-screening platform helps evaluate your overall sensitivity and risk profile when using hallucinogenic drugs."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png" data-react-helmet="true"/>
          <meta name="description" content="Our genetic-based psychedelics pre-screening platform helps evaluate your overall sensitivity and risk profile when using hallucinogenic drugs."/>
          <meta property="og:title" content="HaluGen Life Sciences | DNA Testing For Psychedelic Assisted Therapy" data-react-helmet="true"/>
          <meta property="og:description" content="Our genetic-based psychedelics pre-screening platform helps evaluate your overall sensitivity and risk profile when using hallucinogenic drugs."/>
          <meta property="og:url" content="https://www.halugen.com"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="HaluGen Life Sciences | DNA Testing For Psychedelic Assisted Therapy" data-react-helmet="true"/>
          <meta name="twitter:description" content="Our genetic-based psychedelics pre-screening platform helps evaluate your overall sensitivity and risk profile when using hallucinogenic drugs."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        {this.renderHero()}
        {this.renderAbout()}
        {this.renderSteps()}
        {this.renderTestimonials()}
        {this.renderKitCTA()}
        {this.renderFAQ()}
        {/*{this.renderAffiliates()}*/}
      </>
    )
  }

  scrollToAbout() {
    this.scrollSmoothHandler()
  }

  renderHero() {
    return (<Hero scroll={this.scrollToAbout}/>)
  }

  renderAbout() {
    return (
      <section ref={this.scrollToAboutSection} id="about">
        <div ref={this.scrollToAboutSectionMobile} className='position-relative' style={{top: -125}}></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 p-0">

              <img src={dna} className="feature-half-two" alt="image"/>
            </div>
            <div className="col-lg-6">
              <div className="about-box">
                <div className="title">
                  <h2 className="mb-3">DNA testing for psychedelic assisted psychotherapy.</h2>
                  <p>Our leading genetic-based psychedelics pre-screening platform helps evaluate your overall
                    sensitivity and risk profile when using hallucinogenic drugs.</p>
                </div>

                <ul className="about-skill">

                  <li className="about-item">
                    <h5 className="mb-3"><i className="gradient-text1 fa fa-users" aria-hidden="true"></i> Explore Insights</h5>
                    <p>Discover how genetics can affect your psychedelic response.</p>
                  </li>

                  <li className="about-item">
                    <h5 className="mb-3"><i className="gradient-text1 fa fa-clock-o" aria-hidden="true"></i> Rapid Results</h5>
                    <p>Get your rapid DNA test results from our lab within days.</p>
                  </li>
                </ul>
                <Link to="/how-it-works" className="btn btn-gradient btn-rounded btn-large mt-5">Learn How It Works</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  renderSteps() {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <section id="app" className="bg-light">
          <div className="container">

            <div className="row">
              <div className="title d-inline-block">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <h2 className="gradient-text1 mb-md-3 text-center fadeInUp">A few simple steps for your genetic results</h2>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row align-items-center text-center">

              <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="col-md-4 wow ">
                <div className="app-feature">
                  <i className="fa fa-shopping-cart gradient-text1" aria-hidden="true"></i>
                  <h4 className="mb-3">Order</h4>
                  <p>Your kit is shipped directly to your home.</p>
                </div>

                <div className="app-feature">
                  <i className="fa fa-desktop gradient-text1" aria-hidden="true"></i>
                  <h4 className="mb-3">Register</h4>
                  <p>Register your kit online to get started.</p>
                </div>
              </ScrollAnimation>


              <ScrollAnimation animateIn="fadeInUpCard" animateOnce={true} className="col-md-4 wow">

                <div className="app-image">
                  <img src={img1} alt="image"/>
                  <OwlCarousel dots={false} loop id="app-slider" items={1} className="owl-carousel owl-theme" autoplay={true}>
                    <div className="item">
                      <img src={img2} alt=""/>
                    </div>
                    <div className="item">
                      <img src={img3} alt=""/>
                    </div>
                    <div className="item">
                      <img src={img4} alt=""/>
                    </div>
                  </OwlCarousel>
                </div>
              </ScrollAnimation>

              <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="col-md-4 wow">

                <div className="app-feature">
                  <i className="fa fa-user gradient-text1" aria-hidden="true"></i>
                  <h4 className="mb-3">Swab</h4>
                  <p>Swab your cheek and return to HaluGen.</p>
                </div>

                <div className="app-feature">
                  <i className="fa fa-file-text-o gradient-text1" aria-hidden="true"></i>
                  <h4 className="mb-3">Explore</h4>
                  <p>Discover and explore your results.</p>
                </div>
              </ScrollAnimation>

            </div>
          </div>
        </section>
      </ScrollAnimation>
    )
  }

  renderTestimonials() {
    return (
      <div>
        <section id="testimonials" className="p-0">
          <div className="container-fluid">
            <div className="row">

              <div className="col-md-6">
                <div className="title mt-5 mb-1 m-md-3 m-lg-4 m-xl-5">
                  <h4 style={{lineHeight: "1.4"}}>Genetics, pre-screening surveys and educational resources to help you gain key insights into your psychedelic journey.</h4>
                </div>
                <OwlCarousel loop items={1} id="testimonial_slider" className="owl-carousel mt-0" autoplay={true}>

                  <div className="testimonial-item item">
                    <div>
                      <img srcSet={`${geneticReports} 2x`} alt="image"></img>
                    </div>
                    <h5 className="font-weight-500 third-color my-3">Genetic Reports</h5>
                  </div>

                  <div className="testimonial-item item">
                    <div>
                      <img srcSet={`${lifestyleSurveys} 2x`} alt="image"></img>
                    </div>
                    <h5 className="font-weight-500 third-color my-3">Pre-Screening Surveys</h5>
                  </div>

                  <div className="testimonial-item item">
                    <div>
                      <img srcSet={`${educationalResources} 2x`} alt="image"></img>
                    </div>
                    <h5 className="font-weight-500 third-color my-3">Educational Resources</h5>
                  </div>

                  {/* //old testimonials//
                  <div className="testimonial-item item">
                    <i className="fa fa-quote-right testimonial-icon gradient-text1"></i>
                    <p className="mb-4">HaluGen's psychedelics pre-screening platform is a revolutionary technology
                      that incorporates genetics, lifestyle and family history data to help
                      individuals gain key insights into safer and personalized psychedelic therapies.
                    </p>
                    <div className="testimonial-image">
                      <img src={franco} alt="image"/>
                    </div>
                    <h5 className="font-weight-500 third-color">Franco Vigile</h5>
                    <span className="destination">Founder, HaluGen Life Sciences</span>
                  </div>
                  <div className="testimonial-item item">
                    <i className="fa fa-quote-right testimonial-icon gradient-text1"></i>
                    <p className="mb-4">There is a pressing need for informed access to psychedelics
                      as they do have risks and are not suitable for everyone. Advanced genetic testing
                      can be the key to safer use of these revolutionary therapies.
                    </p>
                    <div className="testimonial-image">
                      <img src={nikolal} alt="image"/>
                    </div>
                    <h5 className="font-weight-500 third-color">Nikolai Vassev</h5>
                    <span className="destination">Founder, Mindleap</span>
                  </div>
                  */}
                </OwlCarousel>
              </div>

              <div className="col-md-6 p-0">

                <div className="counters d-flex align-items-center text-left bg-img-data-counter">

                  <div className="bg-overlay gradient-bg1 opacity-8"></div>
                  <div className="counter-row">

                    <div className="counter-item">
                      <LazyLoad>
                        <CountUp start={0} end={7} duration={3} className="count alt-font d-inline-block"/>
                        <h6 className="text-white">Genetic Markers</h6>
                      </LazyLoad>
                    </div>

                    <div className="counter-item">
                      <LazyLoad>
                        <CountUp start={0} end={5} duration={3} className="count alt-font d-inline-block"/>
                        <h6 className="text-white">Surveys & Questionnaires</h6>
                      </LazyLoad>
                    </div>

                    <div className="counter-item">
                      <LazyLoad>
                        <CountUp start={0} end={70} duration={2} className="count alt-font d-inline-block"/>
                        <h2 className="text-white d-inline-block">+</h2>
                        <h6 className="text-white">Research Papers</h6>
                      </LazyLoad>
                    </div>

                    <div className="counter-item">
                      <LazyLoad>
                        <CountUp start={0} end={5000} duration={2} className="count alt-font d-inline-block"/>
                        <h2 className="text-white d-inline-block">+</h2>
                        <h6 className="text-white">Hours of R&D</h6>
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  renderAffiliates() {
      return (
          <div>
              <section id="affiliates" className="p-0">
                  <div className="container-fluid">
                      <div className="row">
                          <div className="col-md-12 p-0">

                              <div className="counters d-flex align-items-center text-left bg-img-affiliate">

                                  <div className="bg-overlay gradient-bg1 opacity-8"></div>
                                <div className="container">
                                  <div className="row mt-5">
                                    <div className="col-md-12">
                                      <div className="title text-center">
                                        <p className="text-white mb-2">JOIN HALUGEN'S AFFILIATE PROGRAM</p>
                                        <h2 className="text-white">Become an affiliate. Earn commissions.</h2>
                                        <div className="text-center mt-5">
                                          <Link to="/affiliate-program" className="btn btn-white btn-rounded btn-large">Learn More</Link>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>
      )
  }

  renderKitCTA() {
    return <ExploreKitCTA product={this.props.product}/>
  }

  renderFAQ() {
    return (
      <div>
        <section id="faq">
          <div className="container">

            <div className="row">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                <div className="title d-inline-block">
                  <h2 className="gradient-text1 mb-md-3">Frequently Asked Questions</h2>
                </div>
              </ScrollAnimation>
            </div>

            <section className="accordion pt-2 pb-1" id="accordionReportPages">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                      Who can use your service?
                    </button>
                  </h5>
                </div>
                <div id="collapse0" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <p>Our service is available in Canada and the continental U.S. to individuals at least 18 years old. We do not service residents of Quebec at this time. For international orders,
                      please contact us at  <a
                        href="mailto:info@halugen.com">info@halugen.com</a>.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                      How is my privacy protected?
                    </button>
                  </h5>
                </div>
                <div id="collapse1" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <p>We only examine a few relevant genes applicable to your test. Your DNA sample is
                      destroyed as part of the testing process.</p>
                    <p>We do not store or archive your DNA sample in any way</p>
                    <p>We will tell you how we collect, use and store your information</p>
                    <p>You may request deletion of your account and information at any time</p>
                    <p>We will not sell, lease or rent your individual personal information to any third
                      party without your explicit consent</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                      How accurate and relevant are my results?
                    </button>
                  </h5>
                </div>
                <div id="collapse2" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <p>Our genetic testing service uses gold-standard Polymerase Chain Reaction (PCR)
                      technology. Your
                      sample is handled and processed at our own testing facility, in a controlled
                      environment. Our team
                      of scientists and experts have done extensive research to develop personalized insights
                      and reports
                      for the various tests and services we offer.</p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                      How long does the testing process take?
                    </button>
                  </h5>
                </div>
                <div id="collapse3" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <p>You should receive your swab kit within a few days of placing your order. We've included
                      a prepaid return envelope with expedited shipping for your sample.</p>
                    <p>Your test is processed in HaluGen’s state-of-the-art testing facility in Toronto, Canada
                      with results delivered directly to your online dashboard, typically within 1–2 business days of us
                      receiving your sample. For orders outside of Canada, the time to receive your results may increase due to
                      shipping and logistics.</p>
                  </div>
                </div>
              </div>

            </section>

          </div>
        </section>
      </div>
    )
  }
}

export default Home
