import React from "react";
import {Link} from "react-router-dom";
import '../Dashboard.scss'

class SwabKitTutorial extends React.PureComponent {

  render() {
    return (
      <div>
        <div>
          <div className="container my-3 my-sm-5">
            <h1 className="text-center text-primary mb-4 mb-sm-5">Swab Kit Tutorial</h1>
            <div className="tutorial-video-container mb-5">
              <iframe allowFullScreen="" frameBorder="0" src="https://player.vimeo.com/video/497704711?portrait=0&amp;title=0&amp;byline=0"></iframe>
            </div>
            <div className="row pb-sm-4">
              <div className="col-sm-6 col-lg-4 swab-instructions-card">
                <div className="d-inline-flex mb-0">
                  <h4 className="swab-instructions-number">1</h4>
                  <h4 className="my-auto">Register and watch</h4>
                </div>
                <div className="my-3"><img src={require('../../../assets/images/how-to-swab-step-01-2x.png')} width="100%"/></div>
                <div>
                  <p>Register your kit and watch the swabbing tutorial video.<br></br></p>
                  <hr></hr>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 swab-instructions-card">
                <div className="d-inline-flex mb-0">
                  <h4 className="swab-instructions-number">2</h4>
                  <h4 className="my-auto">No food or drink</h4>
                </div>
                <div className="my-3"><img src={require('../../../assets/images/how-to-swab-step-02-2x.png')} width="100%"/></div>
                <div>
                  <p>Avoid eating, drinking and nicotine 30 minutes prior to swabbing.<br></br></p>
                </div>
                <hr></hr>
              </div>
              <div className="col-sm-6 col-lg-4 swab-instructions-card">
                <div className="d-inline-flex mb-0">
                  <h4 className="swab-instructions-number">3</h4>
                  <h4 className="my-auto">Uncap swab tip</h4>
                </div>
                <div className="my-3"><img src={require('../../../assets/images/how-to-swab-step-03-2x.png')} width="100%"/></div>
                <div>
                  <p>Open the swab cap and slide it 2/3rds down the shaft. Use the cotton tip to swab for cheek cells.</p>
                </div>
                <hr></hr>
              </div>
              <div className="col-sm-6 col-lg-4 swab-instructions-card">
                <div className="d-inline-flex mb-0">
                  <h4 className="swab-instructions-number">4</h4>
                  <h4 className="my-auto">Swab both cheeks</h4>
                </div>
                <div className="my-3"><img src={require('../../../assets/images/how-to-swab-step-04-2x.png')} width="100%"/></div>
                <div>
                  <p>Firmly rub and rotate the entire cotton tip against right cheek for 30 seconds. Repeat with the left cheek.</p>
                </div>
                <hr></hr>
              </div>
              <div className="col-sm-6 col-lg-4 swab-instructions-card">
                <div className="d-inline-flex mb-0">
                  <h4 className="swab-instructions-number">5</h4>
                  <h4 className="my-auto">Recap swab tip</h4>
                </div>
                <div className="my-3"><img src={require('../../../assets/images/how-to-swab-step-05-2x.png')} width="100%"/></div>
                <div>
                  <p>Slide the swab cap back over cotton tip and recap.<br></br></p>
                </div>
                <hr></hr>
              </div>
              <div className="col-sm-6 col-lg-4 swab-instructions-card">
                <div className="d-inline-flex mb-0">
                  <h4 className="swab-instructions-number">6</h4>
                  <h4 className="my-auto">Return within 24 hrs</h4>
                </div>
                <div className="my-3"><img src={require('../../../assets/images/how-to-swab-step-06-2x.png')} width="100%"/></div>
                <div>
                  <p>Place the swab and completed ID card in the return envelope.<br></br>Return within 24 hours of swabbing.<br></br></p>
                </div>
                <hr></hr>
              </div>
            </div>
            <div className="text-center mb-4">
              <Link to={'/dashboard/overview'} className="btn btn-primary btn-lg border rounded">Return
              To Dashboard
              </Link>
            </div>
          </div>
        </div>

      </div>
    )
  }

}
export default SwabKitTutorial
