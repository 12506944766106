import * as types from './types'

export function addProductToCart(quantity, productType) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch(addToCart(quantity, productType))
    });
  }
}

export const addToCart = (quantity, productType) => {
  return {
    type: types.ADD_TO_CART,
    payload: {quantity, productType},
  }
}

export function emptyCart() {
  return (dispatch, getState) => {
    dispatch(emptyAllCart());

  }
}

export function emptyAllCart() {
  return {
    type: types.EMPTY_CART,
  }
}
