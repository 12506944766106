import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../actions/index'
import Startup from "./Startup";

const mapStateToProps = state => ({
  user: state.LoginStatus
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const StartupContainer = connect(mapStateToProps, mapDispatchToProps)(Startup);

export default StartupContainer
