import * as types from './types'
import {bfetch} from "../lib/http";
import {REGISTER_SWAB_KIT} from "../lib/constants";

export const registerSwabKit = (swabKitId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(REGISTER_SWAB_KIT, {
        method: 'POST',
        body: swabKitId
      }).then(res => {
        dispatch(doRegisterSwabKitSuccess(res))
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const doRegisterSwabKitSuccess = response => {
  return {
    type: types.REGISTER_SWAB_KIT,
    payload: {response},
  }
}
