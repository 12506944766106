export function localStorageSet(key, value) {
  try {
    localStorage.setItem(key, value)
  } catch (e) {

  }
}

export function localStorageGet(key) {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    return undefined
  }
}

export function localStorageRemove(key) {
  try {
    localStorage.removeItem(key)
  }catch (e){

  }
}
