import React from 'react'
import './Hero.scss'
import {Link} from "react-router-dom";

class BodyTwo extends React.PureComponent {

  render() {
    return (
      <div style={{minHeight: '100vh'}} className='d-flex flex-column w-100 h-100 justify-content-center align-items-center'>
        <div className='dark-slider fadeInLeft_2' style={{overflow: 'hidden'}}>
          <h2 className="font-weight-100 pb-1 pb-sm-3">HaluGen is</h2>
        </div>
        <div className='dark-slider fadeInUp_2' style={{overflow: 'hidden'}}>
          <h2 className="font-weight-600 alt-color pb-2 pb-sm-4 fadeOutDown">Revolutionizing</h2>
        </div>
        <div className='dark-slider fadeInUp_2'>
          <h2 className="font-weight-100 pb-1 pb-sm-3">Psychedelic Therapy</h2>
        </div>
        <div className='pb-3 pb-sm-5 zoomInCustom'>
          <div className='dark-slider'>
            <p className="d-none d-sm-block text-black">Learn how your genetics can impact your psychedelic response.</p>
          </div>
          <div className='dark-slider'>
            <p className="d-block d-sm-none mt-3 text-black">Learn how your genetics can impact<br /><br />your psychedelic response.</p>
          </div>
        </div>
        <div>
          <div onClick={() => this.props.scroll()} className="btn btn-gradient btn-rounded btn-large scroll mt-3">Learn More</div>
        </div>
      </div>
    )
  }
}

export default BodyTwo
