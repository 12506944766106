import * as types from './types'
import {bfetch} from "../lib/http";
import {DELETE_SURVEY, SUBMIT_SURVEY} from "../lib/constants";

export const submitResultSurvey = (surveryType, result, questionareAnswers) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const uid = getState().LoginStatus.userProfile.id
      bfetch(SUBMIT_SURVEY, {
        method: 'POST',
        body: {surveryType, result, questionareAnswers}
      }).then(res => {
        dispatch(doSurveySaveSuccess(res))
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const doSurveySaveSuccess = response => {
  return {
    type: types.SAVE_SURVEY_SUCCESS,
    payload: {response},
  }
}

export const deleteSurveyResults = (surveyId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const uid = getState().LoginStatus.userProfile.id
      const url = DELETE_SURVEY.replace('${id}', surveyId);
      bfetch(url, {
        method: 'DELETE'
      }).then(res => {
        dispatch(doSurveyDelete(surveyId, res))
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const doSurveyDelete = (surveyId, response) => {
  return {
    type: types.DELETE_SURVEY_SUCCESS,
    payload: {surveyId, response},
  }
}
