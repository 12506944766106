import React from "react";
import Helmet from "react-helmet";
import {toast} from "react-toastify";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: undefined,
      email: undefined,
      subject: undefined,
      message: undefined
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.submitForm()
  }

  submitForm() {
    this.props.submitContactUsForm(this.state.name, this.state.email, this.state.subject, this.state.message).then(res => {
      this.setState({name: '', email: '', subject: '', message: ''})
      toast.success("Contact form has been submitted to HaluGen successfully.");
    }).catch(err => {
      toast.error("Something went wrong, please try again!");
    })
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Contact Us | HaluGen Life Sciences</title>


          <meta itemProp="name" content="Contact Us | HaluGen Life Sciences"/>
          <meta itemProp="description" content="Contact HaluGen Life Sciences."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description" content="Contact HaluGen Life Sciences."/>
          <meta property="og:title" content="Contact Us | HaluGen Life Sciences"/>
          <meta property="og:description" content="Contact HaluGen Life Sciences."/>
          <meta property="og:url" content="https://www.halugen.com/contact.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="Contact Us | HaluGen Life Sciences"/>
          <meta name="twitter:description" content="Contact HaluGen Life Sciences."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        <section className="bg-img-contact mt-0">
          <div className="bg-overlay gradient-bg1 opacity-9"></div>
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="title text-center">
                  <p className="text-white mb-2">HAVE A QUESTION?</p>
                  <h2 className="text-white">Contact Us</h2>

                </div>
              </div>
            </div>
          </div>
        </section>


        <section id="contact">
          <div className="container card-contact_container">
            <div className="row justify-content-center">
              <div className="col-lg-6 pr-lg-5">
                <h3 className="gradient-text1 mb-4">HaluGen Life Sciences</h3>

                <h5 className="mb-2">General Inquiries</h5>

                <a href="mailto:info@halugen.com">info@halugen.com</a>

                <div className="my-4"></div>

                <h5 className="mb-2">Media Inquiries</h5>

                <a href="mailto:media@halugen.com">media@halugen.com</a>

                <div className="my-4"></div>

                <h5 className="mb-2">Customer Support</h5>

                <a href="mailto:support@halugen.com">support@halugen.com</a>

                <div className="my-5"></div>

              </div>


              <div className="col-lg-6 mt-1">

                <form onSubmit={this.handleSubmit}>

                  <div className="form-label-group mb-3">
                    <input placeholder="Full Name" className="form-control" required="required" maxLength={250} minLength={1}
                           onChange={(e) => this.setState({name: e.target.value})}
                           value={this.state.name}
                           aria-label="Full Name" type="name" name="name" id="name"/>
                  </div>

                  <div className="form-label-group mb-3">
                    <input placeholder="Your Email" className="form-control" required="required" maxLength={250} minLength={1}
                           onChange={(e) => this.setState({email: e.target.value})}
                           value={this.state.email}
                           aria-label="Your Email" type="email" name="email" id="email"/>
                  </div>

                  <div className="form-label-group mb-3">
                    <input placeholder="Subject" className="form-control" required="required" aria-label="Subject" maxLength={3000} minLength={1}
                           onChange={(e) => this.setState({subject: e.target.value})}
                           value={this.state.subject}
                           type="text" name="subject" id="subject"/>
                  </div>

                  <div className="form-label-group mb-3">
                    <textarea placeholder="Message" className="form-control" rows="5" required="required" maxLength={4999} minLength={1}
                              onChange={(e) => this.setState({message: e.target.value})}
                              value={this.state.message}
                              aria-label="Message" type="text" name="message" id="message"></textarea>
                  </div>

                  <button type="submit" className="btn btn-large btn-gradient">Submit</button>
                </form>
              </div>

            </div>

          </div>

        </section>
      </>
    )
  }

}

export default ContactUs
