import React from 'react'
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {ActionCreators} from "../../actions";
import {connect} from "react-redux";


class PasswordProtect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  render() {
    return (
      <div className='bg-light min-vh-100 min-vw-100'>
        <div>
          <section id="signin">
            <div className="container">
              <section>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 mx-auto my-4">
                    <h2 className="alt-color text-center mb-5">Partners Ordering Sign In</h2>
                    <form onSubmit={this.handleSubmit}>

                      <div className="form-label-group px-1">
                        <input placeholder="Password" className="form-control" required="required" aria-label="Password"
                               type="password" name="password" id="password"
                               onChange={(e) => this.setState({password: e.target.value})}/>
                      </div>

                      {this.state.error ? <div className='text-danger pt-2 px-2'>
                        {this.state.error}
                      </div> : null}

                      <fieldset name="fsaction" id="fsaction">
                        <div className="actions text-center">
                          <input name="commit" value="Sign In" type={'submit'} disabled={this.state.processingLogin}
                                 className="btn btn-large btn-gradient mt-3" data-disable-with="Proceed"
                          />
                        </div>
                      </fieldset>

                    </form>
                  </div>
                </div>
              </section>
            </div>

          </section>
        </div>
      </div>
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({processingLogin: true, error: undefined})
    if (this.state.password === 'halugendirect') {
      this.props.correctPasswordProtectedPage()
      this.props.history.push('/partners')
    } else
      this.setState({processingLogin: false, error: 'Incorrect password, please try again.'})
  }
}


const mapStatetoProps = state => (
  {}
)

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(PasswordProtect));
