import createReducer from './createReducer'
import * as types from '../actions/types'

export const Discounts = createReducer({}, {
  [types.SAVE_DISCOUNT_CODE] (state, action) {
    let data = action.payload.response
    return data
  },
  [types.LOGOUT_SUCCESS] (state, action) {
    return {}
  }
})

export const AffiliateRef = createReducer({}, {
  [types.SAVE_AFFILIATE_REF] (state, action) {
    let data = action.payload.response
    return data
  },
  [types.LOGOUT_SUCCESS] (state, action) {
    return {}
  }
})
