import {combineReducers} from 'redux'
import * as AuthReducers from './auth'
import * as SurveyReducers from './survey'
import * as TestResultsReducers from './test-results'
import * as ProductReducers from './product'
import * as CartReducers from './cart'
import * as DiscountReducers from './discount'

export default combineReducers(
	Object.assign(
		AuthReducers,
		SurveyReducers,
		TestResultsReducers,
		ProductReducers,
		CartReducers,
		DiscountReducers
	)
);
