import React, {Component} from 'react'
import {GoogleMap, withGoogleMap, withScriptjs} from 'react-google-maps'
import {PlaceMarker} from './PlaceMarker'
import './Map.scss';
import ReactStars from "react-rating-stars-component/dist/react-stars";
import {isNullOrUndefined} from "../../lib/helper";

const AirbnbMap = withScriptjs(withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapMounted}
    onZoomChanged={props.handleMapChanged}
    onDragEnd={props.handleMapChanged}
    onBoundsChanged={props.handleMapFullyLoaded}
    defaultCenter={props.center}
    defaultZoom={props.zoom}
    defaultOptions={{mapTypeControl: false, streetViewControl: false}}
  >
    {
      props.places.length > 0 && props.places.map(place => {
        return(<PlaceMarker key={`place${place.Address}`}
                     id={place.Address}
                     lat={place.Latitude}
                     lng={place.Longitude}
                     description={place.Category}
                     name={place.Name}
                     price={place.price}
                     image={require(`../../assets/images/clinics/${place.Image[0]}`)}
                     click={() => props.click.setState({place: place, sidePanelVisible: true})}/>
      )})
    }
  </GoogleMap>
)));

export class Map extends Component {
  constructor(props) {
    super(props)

    this.xMapBounds = {min: null, max: null}
    this.yMapBounds = {min: null, max: null}

    this.mapFullyLoaded = false
    this.zoom = 4

    this.state = {
      lat: 42.6426,
      lng: -115.9171,
      place: undefined,
      sidePanelVisible: true,
      showList: true
    };
  }

  handleMapChanged() {
    this.getMapBounds()
    this.setMapCenterPoint()
  }

  handleMapMounted(map) {
    this.map = map
  }

  handleMapFullyLoaded() {
    if (this.mapFullyLoaded)
      return

    this.mapFullyLoaded = true
    this.handleMapChanged()
  }

  setMapCenterPoint() {
    this.setState({
      lat: this.map.getCenter().lat(),
      lng: this.map.getCenter().lng()
    })
  }

  getMapBounds() {
    var mapBounds = this.map.getBounds()
    var xMapBounds = mapBounds.Eb
    var yMapBounds = mapBounds.mc

    if (yMapBounds && xMapBounds) {
      this.xMapBounds.min = xMapBounds.g
      this.xMapBounds.max = xMapBounds.i

      this.yMapBounds.min = yMapBounds.g
      this.yMapBounds.max = yMapBounds.i
    }
  }

  render() {
    const {lat, lng} = this.state

    return (
      <div>
        {/*DESKTOP VIEW*/}
        <div className='position-relative d-none d-sm-block'>
          {this.state.sidePanelVisible ?
            <div className='position-absolute bg-white overflow-auto shadow-lg'
                 style={{top: 0, width: `450px`, height: `800px`, zIndex: 10}}>
              <div className='d-flex w-100'>
                {!this.state.place ?
                  <div>
                    {this.props.places.map(place => (
                      this.renderPlaceCard(place)
                    ))}
                  </div> :
                  <div className='w-100'>
                    {this.renderResultCard(this.state.place)}
                  </div>}
              </div>
            </div> : null}
          <div style={{height: '50px', width: '30px', top: 15, left: this.state.sidePanelVisible ? 450 : 0, zIndex: 10}}
               className='text-black-50 border-left bg-light shadow-lg position-absolute d-flex align-items-center
             justify-content-center pointer' onClick={() => {
            this.setState({sidePanelVisible: !this.state.sidePanelVisible})
          }}>
            {this.state.sidePanelVisible ?
              <i className='fa fa-chevron-left'></i>
              : <i className='fa fa-chevron-right'></i>
            }
          </div>
        </div>

        {/*MOBILE VIEW*/}
        <div className='d-sm-none mb-2 pl-2'>
          {!this.state.showList
            ? <button className='bg-light' onClick={() => this.setState({showList: true, place: undefined})}>Show
              List</button>
            : <button className='bg-light' onClick={() => this.setState({showList: false})}>Show Map</button>}
        </div>
        <div className='d-block d-sm-none'>
          {this.state.showList ?
            <div className=''>
              {!this.state.place ?
                <div>
                  {this.props.places.map(place => (
                    this.renderPlaceCard(place)
                  ))}
                </div> :
                <div>
                  {this.renderResultCard(this.state.place)}
                </div>}
            </div> :
            <div style={{width: `100%`, height: `800px`}}>
              <AirbnbMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAIjQyLDXSIzN5fOfiDJBrvPCLyXUmoNTc&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{height: `100%`}}/>}
                onMapMounted={this.handleMapMounted.bind(this)}
                handleMapChanged={this.handleMapChanged.bind(this)}
                handleMapFullyLoaded={this.handleMapFullyLoaded.bind(this)}
                center={{
                  lat: lat,
                  lng: lng
                }}
                places={this.props.places}
                zoom={this.zoom}
                click={this}
                containerElement={
                  <div style={{height: `100%`}}/>
                }
                mapElement={
                  <div style={{height: `100%`}}/>
                }
              />
            </div>}
        </div>

        {/*MAP VIEW*/}
        <div className='d-none d-sm-block' style={{width: `100%`, height: `800px`}}>
          {this.state.place ?
            <div className='position-absolute bg-white d-sm-none'
                 style={{bottom: 50, left: 5, height: 200, width: '98%', zIndex: 10}}>
              {this.renderPlaceCard(this.state.place)}
            </div> : null}
          <AirbnbMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAIjQyLDXSIzN5fOfiDJBrvPCLyXUmoNTc&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{height: `100%`}}/>}
            onMapMounted={this.handleMapMounted.bind(this)}
            handleMapChanged={this.handleMapChanged.bind(this)}
            handleMapFullyLoaded={this.handleMapFullyLoaded.bind(this)}
            center={{
              lat: lat,
              lng: lng
            }}
            places={this.props.places}
            zoom={this.zoom}
            click={this}
            containerElement={
              <div style={{height: `100%`}}/>
            }
            mapElement={
              <div style={{height: `100%`}}/>
            }
          />
        </div>
      </div>
    );
  }

  renderResultCard(place) {
    return (
      <div key={place.id} className='py-3'>
        <div className='font-weight-normal mb-2 pointer container-sm'
             onClick={() => this.setState({place: undefined})}>
          <i className='fa fa-long-arrow-left pr-2'></i>Back to results
        </div>
        {this.renderPlaceCard(place, '')}
      </div>
    )
  }

  renderPlaceCard(place, containerClass = 'map-card pointer') {
    return (
      <div key={place.Address} className={containerClass} onClick={() => this.setState({place: place})}>
        <div className='d-flex justify-content-center p-sm-3'>
          <img style={{borderRadius: '25px', width: 'auto', height: '275px'}}
               className='img-fluid d-none d-sm-inline-block'
               src={require('../../assets/images/clinics/' + place.Image[0])}/>
          <img style={{borderRadius: '25px',width: '250px', height: '175px',  backgroundSize: 'cover'}} className='p-1 d-sm-none img-fluid'
               src={require('../../assets/images/clinics/' + place.Image[0])}/>
        </div>
        <div className=' px-3 d-flex py-4' style={{wordBreak: 'break-word'}}>
          <div className='pl-sm-3 pt-2'>
            <h5 className='mb-2'>{place.Name}</h5>
            <div className='badge badge-pill badge-success pt-1'>Clinic</div>
            <div className='mb-3'>
              {!isNullOrUndefined(place.Rating) ?
                <div className='d-flex align-items-center'>
                  <ReactStars
                    count={5}
                    value={Number.parseFloat(place.Rating)}
                    edit={false}
                    size={24}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                  <span className='pl-2'>
                  ({place.Reviews} reviews)
              </span>
                </div>
                : null}
            </div>
            <p className='mb-2 font-weight-bold text-black-50'>{place.Category}</p>
            <div className='d-flex '>
              <i className='fa fa-map-marker pr-2 mt-1'></i>
              <p>{place.Address}</p>
            </div>
            <div className='d-flex'>
              <i className='fa fa-phone pr-2 mt-1'></i>
              <p>{place.Phone}</p>
            </div>
            <div className='d-flex mb-2'>
              <i className='fa fa-at pr-2 mt-1'></i>
              <a href={place.Website} target='_blank' className='text-info'>{place.Website}</a>
            </div>
          </div>
        </div>
        <hr className='m-sm-0'></hr>
      </div>
    )
  }
}

export default Map;
