import React from 'react'
import './Footer.scss'
import {Link, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ActionCreators} from "../../actions";
import ScrollAnimation from "react-animate-on-scroll";

class WholeSalerFooter extends React.Component {
  constructor(props) {
    super(props);

  }


  render() {
    return (
      <section className="bg-light text-center mb-0">
        <div className="container">


          <div className="container text-center text-md-left">
            <div className="row">

              <div className="col-12 mx-auto mb-4">
                <h6 className="gradient-text1 text-uppercase font-weight-bold">
                  <strong>Terms</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px'}}/>

                <p className="mb-1">
                  <Link id="footer-link-privacy" to="/privacy">Privacy Statement</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-terms-of-service" to="/terms-of-service">Terms of Service</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-terms-of-sale" to="/term-of-sale">Terms of Sale</Link>
                </p>
                <p className="mb-1">
                  <Link id="footer-link-refund-policy" to="/refund-policy">Refund Policy</Link>
                </p>
              </div>


            </div>
          </div>

          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <p className="company-about">Your use of our services and the information provided through this
              website, including test results and reports, is for informational purposes only, and is not
              intended for medical advice or treatment, or for other therapeutic or diagnostic use. Before
              taking any action based on this information, you must consult an experienced healthcare
              professional. Your use of our services and this website is governed by the laws of the Province
              of Ontario and the federal laws of Canada. &copy; 2022 HaluGen Life Sciences. All rights
              reserved.</p>
          </ScrollAnimation>

        </div>
      </section>
    )
  }
}

const mapStatetoProps = state => (
  {}
)

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(WholeSalerFooter));

