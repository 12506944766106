import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../../actions'
import ResetPassword from "./ResetPassword";

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const ResetPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export default ResetPasswordContainer
