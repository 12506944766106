import React from "react";
import step1 from '../../assets/images/how-it-works-a-few-steps-01.jpg'
import step1_2x from '../../assets/images/how-it-works-a-few-steps-01-2x.jpg'
import step2 from '../../assets/images/how-it-works-a-few-steps-02.jpg'
import step2_2x from '../../assets/images/how-it-works-a-few-steps-02-2x.jpg'
import step3 from '../../assets/images/how-it-works-a-few-steps-03.jpg'
import step3_2x from '../../assets/images/how-it-works-a-few-steps-03-2x.jpg'
import inkit from '../../assets/images/halugen_kit_items.png'
import inkitx2 from '../../assets/images/halugen_kit_items-2x.png'
import inkitmobile from '../../assets/images/halugen-kit-items-mobile.png'
import privacy from '../../assets/images/how-it-works-privacy.png'
import privacyx2 from '../../assets/images/how-it-works-privacy-2x.png'
import tech from '../../assets/images/how-it-works-dna-report.png'
import techx2 from '../../assets/images/how-it-works-dna-report-2x.png'
import ExploreKitCTA from "../../components/Shared/ExploreKitCTA";
import {Link} from "react-router-dom";
import Helmet from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";

class HowItWorks extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>How It Works | HaluGen Life Sciences</title>

          <meta itemProp="name" content="How It Works | HaluGen Life Sciences"/>
          <meta itemProp="description" content="Swab and return your DNA test sample to receive your personalized results within a few days."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description" content="Swab and return your DNA test sample to receive your personalized results within a few days."/>
          <meta property="og:title" content="How It Works | HaluGen Life Sciences"/>
          <meta property="og:description"
                content="Swab and return your DNA test sample to receive your personalized results within a few days."/>
          <meta property="og:url" content="https://www.halugen.com/how-it-works"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="How It Works | HaluGen Life Sciences"/>
          <meta name="twitter:description"
                content="Swab and return your DNA test sample to receive your personalized results within a few days."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        {this.renderHeader()}
        {this.renderHowItWorks()}
        {this.renderWhatsInYourKit()}
        {this.renderPrivacy()}
        {this.renderPlatform()}
        {this.renderExploreKitCTA()}
      </>
    )
  }

  renderHeader() {
    return (
      <section className="bg-img-howitworks mt-0">
        <div className="bg-overlay gradient-bg1 opacity-7"></div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="title text-center">
                <p className="text-white mb-2">RESULTS WITHIN DAYS</p>
                <h2 className="text-white">Your journey begins with a simple swab.</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  renderHowItWorks() {
    const linkStyle = {textDecoration: "underline"};
    return (
      <section id="works">
        <div className="container">

          <div className="row">
            <div className="col-md-12 text-center">
              <div className="title d-inline-block">
                <h2 className="gradient-text1 mb-md-3">A few steps to follow at home</h2>
              </div>
            </div>
          </div>


          <div className="row no-gutters card-deck">

            <div className="col-lg-4 pb-4 pb-lg-0">
              <div className="card bg-transparent">
                <div className="card-header halugen-step">
                  <h4>1</h4>
                  <h4>Register your kit</h4>
                </div>

                <img className="card-img-top" src={step1}
                     srcSet={step1_2x} focusable="false" role="img" alt=""/>

                <div className="card-body px-0 py-4">
                  <p className="card-text">Your swab kit will arrive at your doorstep. Register your swab kit at:
                    <Link to="/register" style={linkStyle}> www.halugen.com/register</Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pb-4 pb-lg-0">
              <div className="card bg-transparent">
                <div className="card-header halugen-step">
                  <h4>2</h4>
                  <h4>Swab and return</h4>
                </div>

                <img className="card-img-top" src={step2}
                     srcSet={step2_2x} focusable="false" role="img" alt=""/>

                <div className="card-body px-0 py-4">
                  <p className="card-text">Swab your cheek and return in the prepaid shipping envelope. View the
                    <Link to="/swab-kit-instructions" style={linkStyle}> swab kit instructions</Link>.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pb-4 pb-lg-0">
              <div className="card bg-transparent">
                <div className="card-header halugen-step">
                  <h4>3</h4>
                  <h4 className="d-none d-xl-block">Discover and explore</h4>
                  <h4 className="pt-2 pt-lg-0 d-xl-none">Discover and explore</h4>
                </div>

                <img className="card-img-top" src={step3}
                     srcSet={step3_2x} focusable="false" role="img" alt=""/>

                <div className="card-body px-0 py-4">
                  <p className="card-text">Discover and explore personalized reports based on your psychedelic genetic profile.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
    )
  }

  renderWhatsInYourKit() {
    return (
      <div>
        <section id="kit contents" className="bg-light">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="row wow">
              <div className="col-md-12 text-center">
                <div className="title d-inline-block">
                  <h2 className="gradient-text1 mb-md-3">What's in your kit</h2>
                </div>
              </div>
            </ScrollAnimation>


            <ScrollAnimation animateIn="fadeInUpCard" animateOnce={true} className="hiw-kit-fig wow ">
              <img className="img-fluid d-none d-sm-block" src={inkit} data-src={inkit}
                   data-srcset="images/halugen_kit_items-2x.png 2x" width="1110" height="448" alt=""
                   srcSet={inkitx2}/>

              <img className="d-sm-none mb-4" src={inkitmobile} data-src={inkitmobile}
                   alt=""/>

              <ul className="hiw-kit-fig-list pt-3 pt-md-0">
                <li className="hiw-kit-fig-box" data-toggle="tooltip" data-placement="right"
                    title="Swab Collection Kit - This box contains all the necessary contents to send your DNA cheek sample back to our lab.">
                  <strong>Swab Collection Kit</strong> - This box contains all the necessary contents to send your DNA cheek sample
                  back to our lab.
                </li>
                <li className="hiw-kit-fig-swab" data-toggle="tooltip" data-placement="right"
                    title="Cotton Cheek Swab - This is the swab used to collect a DNA sample from swabbing your cheek.">
                  <strong>Cotton Cheek Swab</strong> - This is the swab used to collect a DNA sample from swabbing your cheek.
                </li>
                <li className="hiw-kit-fig-instructions" data-toggle="tooltip" data-placement="right" title="Instructions Card - These step-by-step instructions provide all the details
                    necessary to guide you through the swabbing and return process.">
                  <strong>Instructions Card</strong> - These step-by-step instructions provide all the details necessary to guide you through the
                  swabbing and return process.
                </li>
                <li className="hiw-kit-fig-return" data-toggle="tooltip" data-placement="top"
                    title="Prepaid Return Envelope - Place your cotton cheek swab in the return envelope and mail it back to our lab.">
                  <strong>Prepaid Return Envelope</strong> - Place your cotton cheek swab in the return envelope and mail it back
                  to our lab.
                </li>
              </ul>
            </ScrollAnimation>


          </div>
        </section>
      </div>
    )
  }

  renderPrivacy() {
    return (
      <div>
        <section id="privacy">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <div className="col-md-12 text-center wow">
                <div className="title d-inline-block">
                  <h2 className="gradient-text1 mb-md-3">Your privacy matters</h2>
                </div>
              </div>
            </ScrollAnimation>
            <div className="row align-items-center">
              <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="col-md-6 order-md-2 wow ">

                <div className="card-content-text">
                  <h2>We believe you should be in control of your information.</h2>
                  <ul className="card-text">
                    <li>We only examine a few relevant genetic markers and your DNA sample is destroyed as part of the testing process</li>
                    <li>We will not sell, lease or rent your individual personal information to any third party without your explicit consent</li>
                    <li>You may request the deletion of your account and information at any time</li>
                  </ul>
                </div>
              </ScrollAnimation>
              <div className="col-md-6">

                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="card-content-image wow hover-effect ">
                  <img src={privacy} srcSet={privacyx2}
                       focusable="false" role="img" alt=""/>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  renderPlatform() {
    return (
      <div>
        <section id="tech-platform" className="bg-light">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="col-md-12 text-center wow">
              <div className="title d-inline-block">
                <h2 className="gradient-text1 mb-md-3">Our technology platform</h2>
              </div>
            </ScrollAnimation>

            <div className="row align-items-center">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="col-md-6 wow">

                <div className="card-content-text">
                  <h2>Gold-standard DNA testing.</h2>
                  <ul className="card-text">
                    <li>Our genetic testing service uses gold-standard Polymerase Chain Reaction (PCR) technology,
                      which is a well-established method for genotyping and analyzing DNA
                    </li>
                    <li>Your sample is handled and processed at our own testing facility using our advanced
                      testing platform, in a controlled environment
                    </li>
                    <li>Our team of scientists and experts has done extensive scientific research to develop
                      personalized insights, services and reports
                    </li>
                  </ul>
                </div>
              </ScrollAnimation>
              <div className="col-md-6">

                <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="card-content-image text-right hover-effect wow ">
                  <img src={tech} srcSet={techx2}
                       focusable="false" role="img" alt=""/>
                </ScrollAnimation>
              </div>
            </div>

          </div>
        </section>
      </div>
    )
  }

  renderExploreKitCTA() {
    return <ExploreKitCTA bg={'#ffffff'}  product={this.props.product} />
  }
}

export default HowItWorks
