import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../actions/index'
import AffiliateProgram from "./AffiliateProgram";

const mapStateToProps = state => ({
  userCountry: state.UserCountry.country
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const AffiliateContainer = connect(mapStateToProps, mapDispatchToProps)(AffiliateProgram);

export default AffiliateContainer
