import React from 'react'
import './Hero.scss'
import image1 from "../../../assets/images/bg-img-slider1.jpg";
import image2 from "../../../assets/images/bg-img-slider2.jpg";
import BodyOne from "./BodyOne";
import BodyTwo from "./BodyTwo";
import {APP_STARTED} from "../../../App";
import ScrollAnimation from "react-animate-on-scroll";


class Hero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      ready: false
    }
    if (APP_STARTED) {
      this.state.ready = true;
    }

    this.setReady = this.setReady.bind(this);
  }

  setReady() {
    this.setState({ready: true})
  }

  componentDidMount() {
    if (!APP_STARTED)
      setTimeout(this.setReady, 1000);

    setInterval(() => {
      if (this.state.page === 1)
        this.setState({page: 2})
      else
        this.setState({page: 1})

    }, 15000)

  }

  render() {
    if (!this.state.ready) {
      return (
        <div style={{minHeight: '100vh', minWidth: '100wh'}}></div>
      )
    }
    let img = image1;
    if (this.state.page === 2) {
      img = image2;
    }
    return (
      <>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={1250}>
          <div className='hero' style={{backgroundImage: `url(${img})`}}>
            <img src={image1} style={{height: 0, width: 0}}/>
            <img src={image2} style={{height: 0, width: 0}}/>

            {this.state.page === 1 ? <BodyOne scroll={this.props.scroll}/> : <BodyTwo scroll={this.props.scroll}/>}

            <div className="slider-social">
              <ul className="list-unstyled">
                <li><a className="facebook-text-hvr bg-transparent" style={{color: 'black'}} href="https://www.facebook.com/halugensci" target="_blank"><i
                  className="fa fa-facebook"
                  aria-hidden="true"></i></a></li>
                <li><a className="twitter-text-hvr bg-transparent" style={{color: 'black'}} href="https://twitter.com/halugensci" target="_blank"><i
                  className="fa fa-twitter"
                  aria-hidden="true"></i></a></li>
                <li><a className="linkedin-text-hvr bg-transparent" style={{color: 'black'}} href="https://www.linkedin.com/company/halugensci"
                       target="_blank"><i
                  className="fa fa-linkedin"
                  aria-hidden="true"></i></a></li>
                <li><a className="instagram-text-hvr bg-transparent" style={{color: 'black'}} href="https://www.instagram.com/halugensci"
                       target="_blank"><i
                  className="fa fa-instagram"
                  aria-hidden="true"></i></a></li>
              </ul>
            </div>
            <div onClick={() => this.props.scroll()} className="scroll-down scroll text-black pointer">Scroll Down
              <i className="fa fa-long-arrow-down text-black"></i></div>
          </div>
        </ScrollAnimation>
      </>
    )
  }
}

export default Hero;
