import createReducer from "./createReducer";
import * as types from "../actions/types";


export const TestResults = createReducer([], {
  [types.LOGIN_SUCCESS](state, action) {
    let data = action.payload.response.res;
    const customerDetails = action.payload.response.res_cus;
    data = {...data, ...customerDetails}
    if (data.psychedelicTestResults)
      return data.psychedelicTestResults
    else
      return [];
  },
  [types.REGISTER_SWAB_KIT](state, action) {
    var newState = Object.assign([], state);
    newState.push(action.payload.response)
    return newState;
  },
  [types.LOGOUT_SUCCESS](state, action) {
    return []
  }
})

export const InitialLoad = createReducer(false, {
  [types.LOGIN_SUCCESS](state, action) {
    return true
  },
  [types.LOGOUT_SUCCESS](state, action) {
    return false
  }
});
