import React from "react";
import postscribe from 'postscribe';

class Checkout extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <>


      </>
    )
  }
}

export default Checkout
