import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from "../../../actions";
import AdminHeader from "./AdminHeader";

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const AdminHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(AdminHeader);

export default AdminHeaderContainer
