import createReducer from './createReducer'
import * as types from '../actions/types'
import {localStorageGet, localStorageRemove, localStorageSet} from "../lib/localStorage-helper";
import {isNullOrUndefined} from "../lib/helper";


const cart = {
  cart: {
    itemCount: 0,
    productType: undefined
  }
}

function fetchCart() {
  const cartResult = localStorageGet('HALUGEN_public-cart')
  if (isNullOrUndefined(cartResult)) {
    return cart
  } else {
    return JSON.parse(cartResult);
  }
}

export const Cart = createReducer(fetchCart(), {
  [types.ADD_TO_CART](state, action) {
    var newState = Object.assign({}, state);
    const quantity = action.payload.quantity;

    if (newState.cart.productType !== action.payload.productType) {
      newState.cart.productType = action.payload.productType
      newState.cart.itemCount = quantity
    } else {
      newState.cart.itemCount = newState.cart.itemCount + quantity
    }

    if (newState.cart.itemCount < 0) { //min number of test someone can order
      newState.cart.itemCount = 0
    }
    if (newState.cart.itemCount > 25) { //max number of test someone can order
      newState.cart.itemCount = 25
    }
    localStorageSet('HALUGEN_public-cart', JSON.stringify(newState));
    return newState;
  },
  [types.EMPTY_CART](state, action) {
    var newState = Object.assign({}, state);
    newState = {
      cart: {
        itemCount: 0
      }
    };
    localStorageRemove('HALUGEN_public-cart');
    return newState;
  }
})
