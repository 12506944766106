import React from "react";
import {Link, withRouter} from "react-router-dom";
import ProfileContainer from "../Profile/ProfileContainer";
import ChangePasswordContainer from "../Password/ChangePasswordContainer";
import {Badge} from "reactstrap";

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      tab: 'dashboard'
    }

    if(window.location.pathname.includes('/partner-resource')){
      this.state.tab = 'partner'
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if(this.props.location.pathname.includes('/partner-resource')){
        this.setState({tab:'partner'})
      }
    }
  }

  render() {
    return (
      <div>
        <header className="gradient-bg1">
          <div className="container mb-4">
            <nav className="navbar navbar-dark navbar-expand halugen-nav">
              <div className="container-fluid">
                <Link className="navbar-brand d-none d-sm-block logo" to="/dashboard/overview">
                  <img src={require('../../../assets/images/halugen-logo-white.png')}/>
                </Link>
                <Link className="navbar-brand d-sm-none logo" to="/dashboard/overview">
                  <img src={require('../../../assets/images/halugen-square-logo-white.png')}/>
                </Link>
                <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1" style={{borderStyle: 'none'}}><span
                  className="sr-only">Toggle navigation</span><span className="navbar-toggler-icon" style={{borderColor: 'rgba(255,255,255,0)'}}/>
                </button>
                <div className="collapse navbar-collapse m-auto" id="navcol-1">
                  <ul className="nav navbar-nav mx-auto">
                    <li className="nav-item">
                      <Link className={"nav-link pointer " + this.isNavActive('dashboard')} to="/dashboard/overview"
                            onClick={() => {this.setState({tab:'dashboard'})}}>Dashboard</Link>
                    </li>
                    <li className="nav-item">
                      <Link to='/partner-resource/overview' className={"nav-link pointer " + this.isNavActive('partner')}
                      onClick={() => {this.setState({tab:'partner'})}}>Resources <sup><Badge className='badge text-black bg-warning'>
                        NEW</Badge></sup></Link>
                    </li>
                  </ul>
                  <ul className="nav navbar-nav">
                    <li className="nav-item mx-auto">
                      <div className="nav-item dropdown"><a className="active text-white" data-toggle="dropdown" aria-expanded="false" href="#"><i
                        className="fa fa-user-circle nav-user-icon"/></a>
                        <div className="dropdown-menu dropdown-menu-right fade" id="user-dropdown">
                          <a className="dropdown-item pointer" data-toggle="modal" data-target="#profile-settings-modal">
                            Profile Settings</a>
                          <Link to='/' className="dropdown-item" onClick={() => this.handleSignOut()}>Sign Out</Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <ProfileContainer/>
        <ChangePasswordContainer/>
      </div>
    )
  }

  handleSignOut() {
    this.props.doLogout();
  }

  isNavActive(type) {
    if(this.state.tab === type){
      return 'active'
    }
    return "";
  }
}
export default withRouter(Header);
