import * as AuthActions from './auth.js'
import * as SurveyActions from './survey.js'
import * as SwabKitAction from './swab-kits.js'
import * as CartAction from './cart.js'
import * as ProductAction from './product.js'
import * as AdminAction from './admin.js'
import * as ContactUsAction from './contact-us.js'
import * as DiscountAction from './discount.js'

export const ActionCreators = Object.assign({},
	AuthActions,
	SurveyActions,
	SwabKitAction,
	CartAction,
	ProductAction,
	AdminAction,
	ContactUsAction,
	DiscountAction
);
