import React from 'react'
import {Route} from 'react-router-dom'
import WholeSalerHeader from "../components/Header/WholeSalerHeader";
import WholeSalerFooter from "../components/Footer/WholeSalerFooter";

const WholeSalerRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      <div className={'main-website'}>
        <WholeSalerHeader/>
        <Component {...props} />
        <WholeSalerFooter/>
      </div>
    )}/>
  )
}

export default WholeSalerRoute
