import React from "react";
import {Link} from "react-router-dom";
import Helmet from "react-helmet";
import {isNullOrUndefinedOrEmpty} from "../../../lib/helper";

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: undefined,
      password: undefined,
      processingLogin: false,
      rememberMe: false,
      error: undefined
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  loginUser() {
    this.setState({processingLogin: true, error: undefined})
    this.props.doLogin(this.state.email, this.state.password, this.state.rememberMe).then(res => {
      this.setState({processingLogin: false, error: undefined})
      if (!isNullOrUndefinedOrEmpty(res.authorities) && res.authorities.includes('ROLE_ADMIN')) {
        this.props.history.push('/admin')
      }else{
        this.props.history.push('/dashboard/overview')
      }

    }).catch(err => {
      this.setState({processingLogin: false, error: 'Your email or password is incorrect.'})
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.loginUser()
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Sign In | HaluGen Life Sciences</title>

          <meta itemProp="name" content="Sign In | HaluGen Life Sciences"/>
          <meta itemProp="description" content="Sign In for HaluGen Life Sciences."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description" content="Sign In for HaluGen Life Sciences."/>
          <meta property="og:title" content="Sign In | HaluGen Life Sciences"/>
          <meta property="og:description" content="Sign In for HaluGen Life Sciences."/>
          <meta property="og:url" content="https://www.halugen.com/sign-in.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="Sign In | HaluGen Life Sciences"/>
          <meta name="twitter:description" content="Sign In for HaluGen Life Sciences."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        <section id="signin">
          <div className="container">
            <section>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 mx-auto my-4">
                  <h2 className="alt-color text-center mb-5">Sign in</h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-label-group mb-3 px-1">
                      <input placeholder="Email" className="form-control" required="required"
                             aria-label="Email" type="email" name="email" id="email"
                             onChange={(e) => this.setState({email: e.target.value})}/>
                    </div>

                    <div className="form-label-group px-1">
                      <input placeholder="Password" className="form-control" required="required" aria-label="Password"
                             type="password" name="password" id="password"
                             onChange={(e) => this.setState({password: e.target.value})}/>
                    </div>

                    <div className="form-label-group px-1 py-3">
                      <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox"
                               id="formCheck-remember-me" checked={this.state.rememberMe}
                               onChange={(e) => this.setState({rememberMe: !this.state.rememberMe})}
                               name="marketing-opt-in"/>
                        <label className="custom-control-label" htmlFor="formCheck-remember-me">Remember Me</label></div>
                    </div>
                    {this.state.error ? <div className='text-danger pt-2 px-2'>
                      {this.state.error}
                    </div> : null}

                    <fieldset name="fsaction" id="fsaction">
                      <div className="actions text-center">
                        <input name="commit" value="Sign in" type={'submit'} disabled={this.state.processingLogin}
                               className="btn btn-large btn-gradient mt-3" data-disable-with="Sign in"
                        />
                      </div>
                    </fieldset>

                  </form>

                  <p className="text-center mt-3">Forgot your password? <Link to="/forgot-password">Click here</Link>
                  </p>
                </div>
              </div>
            </section>
          </div>

        </section>
      </>
    )
  }
}

export default SignIn
