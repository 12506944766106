import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../actions/index'
import Wholesaler from "./WholeSaler";

const mapStateToProps = state => ({
  product: state.WholeSalerProducts,
  cart: state.Cart,
  affiliateRef: state.AffiliateRef,
  discounts: state.Discounts,
  passwordProtected: state.PasswordProtectedPages.isVisible
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const WholesalerContainer = connect(mapStateToProps, mapDispatchToProps)(Wholesaler);

export default WholesalerContainer
