import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from "../../../actions";
import Header from "./Header";

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const DashboardHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default DashboardHeaderContainer
