import {bfetch} from "../lib/http"
import {SUBMIT_CONTACT_US_FORM} from "../lib/constants";

export const submitContactUsForm = (name, email, subject, message) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(SUBMIT_CONTACT_US_FORM, {
        method: 'POST',
        body: {name, email, subject, message}
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
