export function verifyLegalAge(day, month, year) {
  var today = new Date();
  var ty = today.getUTCFullYear();
  var tm = today.getUTCMonth();
  var td = today.getUTCDate();

  var by = year;
  var bm = month;
  var bd = day;

  var days = 0;
  var months = 0;
  var years = 0;

  if (td < bd) {
    days = (td - bd + 30);
    tm = tm - 1;
  } else {
    days = (td - bd);
  }

  if (tm < bm) {
    months = (tm - bm + 12);
    ty = ty - 1;
  } else {
    months = (tm - bm);
  }
  years = ty - by;

  if (years < 18) {
    return false;
  }

  return true;
}
