import React, {createContext, useState} from 'react'
import './App.scss';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import ScrollToTop from "./components/ScrollToTop";
import LoadingScreen from "./pages/LoadingScreen/LoadingScreen";
import {store} from "./store/store";
import {Provider} from "react-redux";
import AboutUs from "./pages/AboutUs/AboutUs";
import HowItWorksContainer from "./pages/HowItWorks/HowItWorksContainer";
import StartupContainer from "./pages/Startup/StartupContainer";
import Checkout from "./pages/Cart/Checkout/Checkout";
import SwabKitTutorial from "./pages/Dashboard/SwabKitTutorial/SwabKitTutorial";
import MainWebsiteRoute from "./layouts/MainWebsiteRoute";
import MainWesbiteAltRoute from "./layouts/MainWebsiteAltRoute";
import MainWebsiteOtherRoute from "./layouts/MainWebsiteOtherRoute";
import DashboardRoute from "./layouts/DashboardRoute";
import OurTestsContainer from "./pages/OurTests/OurTestsContainer";
import CartContainer from "./pages/Cart/CartContainer";
import HomeContainer from "./pages/Home/HomeContainer";
import SignInContainer from "./pages/Auth/SignIn/SignInContainer";
import RegisterContainer from "./pages/Auth/Register/RegisterContainer";
import AdminRoute from "./layouts/AdminRoute";
import ResetPasswordContainer from "./pages/Auth/ResetPassword/ResetPasswordContainer";
import ContactUsContainer from "./pages/ContactUs/ContactUsContainer";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {isNullOrUndefined, isNullOrUndefinedOrEmpty} from './lib/helper'
import {saveAffiliateRef, saveDiscount} from './actions/discount'
import queryString from 'query-string'
import AffiliateContainer from "./pages/AffiliateProgram/AffiliateContainer";
import WholesalerContainer from "./pages/Wholesaler/WholesalerContainer";
import WholeSalerRoute from "./layouts/WholeSalerRoute";
import Clinic from "./pages/Clinic/Clinic";
import PasswordProtect from "./pages/PasswordProtect/PasswordProtect";
import Pdf from "./pages/Pdf/Pdf";


const SwabKitInstructions = React.lazy(() => import('./pages/SwabKitInstructions/SwabKitInstructions'))
const Privacy = React.lazy(() => import('./pages/Privacy/Privacy'))
const TermsOfService = React.lazy(() => import('./pages/TermsOfService/TermsOfService'))
const TermOfSale = React.lazy(() => import('./pages/TermOfSale/TermOfSale'))
const RefundPolicy = React.lazy(() => import('./pages/RefundPolicy/RefundPolicy'))
const ForgotPasswordContainerLazy = React.lazy(() => import('./pages/Auth/ForgotPassword/ForgotPasswordContainer'))
const DashboardContainer = React.lazy(() => import('./pages/Dashboard/DashboardContainer'))
const PartnerOffer = React.lazy(() => import('./pages/Dashboard/PartnerOffer/PartnerOffer'))
const AdminHomeContainer = React.lazy(() => import('./pages/Admin/AdminHome/AdminHomeContainer'))

export const RefreshDataContext = createContext([{}, () => {}]);

export const APP_STARTED = true;

if (process.env.REACT_APP_ENVIRONMENT === 'production')
  Sentry.init({
    dsn: "https://4e37d643d0144b42a307603a68b4d983@o559969.ingest.sentry.io/5695210",
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: 'halugen@' + '1.0.0',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

async function checkTapAffiliate() {
  const redirectParam = window.location.search + '&redirect=true';
  const response = await fetch('https://extreme-ip-lookup.com/json/?key=ULiO68Vga9C2apzRmVdQ')
    .then(res => res.json())
    .then(response => {
      if (response.country === 'Canada') {
        window.location.href = 'https://shop.halugen.com' + redirectParam;
        return true;
      } else {
        window.location.href = 'https://shop.us.halugen.com' + redirectParam;
        return true;
      }
    })
    .catch((data, status) => {
      window.location.href = 'https://shop.us.halugen.com' + redirectParam;
      return true;
    })

}


function App() {
  const [reportCanvas, setReportCanvas] = useState("");
  const reportCanvasStyle = {
    minHeight: "calc(100%)",
    width: "100%",
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9999999,
    display: reportCanvas === "" ? "none" : "block"
  };

  if (!isNullOrUndefinedOrEmpty(window.location.search)) {
    const values = queryString.parse(window.location.search);
    if (!isNullOrUndefined(values.ref)) {
      store.dispatch(saveAffiliateRef(values.ref))
      if (isNullOrUndefinedOrEmpty(values.redirect)) {
        checkTapAffiliate();
        return;
      }
    }
    if (!isNullOrUndefined(values.discount)) {
      store.dispatch(saveDiscount(values.discount))
    }
  }
  return (
    <RefreshDataContext.Provider value={[reportCanvas, (val) => setReportCanvas(val),]}>
      <Provider store={store}>
        <ToastContainer/>
        <BrowserRouter>
          <StartupContainer/>
          <LoadingScreen/>
          <ScrollToTop/>
          <div className="App">
            <Main></Main>
          </div>
        </BrowserRouter>
      </Provider>
      <div key="reportCanvas" id="reportCanvas" style={reportCanvasStyle} onClick={() => setReportCanvas('')}>
        {reportCanvas}
      </div>
    </RefreshDataContext.Provider>
  );
}

function Main() {
  return (
    <React.Suspense fallback={
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
      </div>
    }>
      <main>
        <Switch>
          <MainWebsiteRoute exact path='/' component={HomeContainer}/>
          <MainWesbiteAltRoute exact path='/about' component={AboutUs}/>
          <MainWesbiteAltRoute exact path='/clinics' component={Clinic}/>
          <MainWesbiteAltRoute exact path='/contact' component={ContactUsContainer}/>
          <MainWesbiteAltRoute exact path='/how-it-works' component={HowItWorksContainer}/>
          <MainWebsiteOtherRoute exact path='/test' component={OurTestsContainer}/>
          <MainWebsiteOtherRoute exact path='/sign-in' component={SignInContainer}/>
          <MainWebsiteOtherRoute exact path='/forgot-password' component={ForgotPasswordContainerLazy}/>
          <MainWebsiteOtherRoute exact path='/register' component={RegisterContainer}/>
          <MainWebsiteOtherRoute exact path='/account/reset/finish' component={ResetPasswordContainer}/>
          <MainWebsiteOtherRoute exact path='/cart' component={CartContainer}/>
          <MainWebsiteOtherRoute exact path='/checkout' component={Checkout}/>
          <MainWebsiteOtherRoute exact path='/privacy' component={Privacy}/>
          <MainWebsiteOtherRoute exact path='/terms-of-service' component={TermsOfService}/>
          <MainWebsiteOtherRoute exact path='/term-of-sale' component={TermOfSale}/>
          <MainWebsiteOtherRoute exact path='/refund-policy' component={RefundPolicy}/>
          <MainWebsiteOtherRoute exact path='/swab-kit-instructions' component={SwabKitInstructions}/>
          <MainWebsiteOtherRoute exact path='/affiliate-program' component={AffiliateContainer}/>

          <WholeSalerRoute exact path='/partners/sign-in' component={PasswordProtect}/>
          <WholeSalerRoute exact path='/partners' component={WholesalerContainer}/>

          <DashboardRoute exact path='/dashboard/:tab' component={DashboardContainer}/>
          <DashboardRoute exact path='/partner-resource/:tab' component={PartnerOffer}/>
          <DashboardRoute exact path='/tutorial' component={SwabKitTutorial}/>
          <DashboardRoute exact path='/pdf' component={Pdf}/>

          <AdminRoute exact path='/admin' component={AdminHomeContainer}/>

          <Route>
            <Redirect to={'/'}/>
          </Route>
        </Switch>
      </main>
    </React.Suspense>
  );
}

export default App;
