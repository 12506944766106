import React from 'react'
import Map from '../../components/Map/Map'
import {Helmet} from 'react-helmet'
import {bindActionCreators} from "redux";
import {ActionCreators} from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


class Clinic extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}

  }


  componentDidMount() {
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Clinics near you | Halugen</title>
          <meta name="description"
                content={'Explore clinics on Halugen. Get professional help to resolve any problems you are facing.'}/>
        </Helmet>
      </>
    )
  }

  render() {
    return (
      <div className='bg-light'>
        {this.renderMetaTags()}
        {this.renderHeader()}
        {this.renderMap()}
      </div>
    );
  }

  renderHeader() {
    return (
      <section className="bg-img-howitworks mt-0" style={{height: '250px'}}>
        <div className="bg-overlay gradient-bg1 opacity-7"></div>
        <div className="container">
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="title text-center">
                <h2 className="text-white">Clinics near you</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  renderMap() {
    return (
      <section className='py-3'>
        <Map/>
      </section>
    )
  }
}


const mapStatetoProps = state => (
  {
  }
)

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Clinic));
